import ModalLayout from 'components/modal/ModalLayout';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';

type Props = {};

export default function SettingSkeleton({}: Props) {
  return (
    <div className='h-full flex flex-col justify-between items-center'>
      <div className='w-full flex items-center justify-center relative'>
        <button className='p-2 absolute left-0'>
          <BackIcon fill='#9C9C9C' />
        </button>
        <h2 className='text-base font-bold'>메뉴 설정</h2>
      </div>
      <div className='w-full px-5 overflow-scroll py-8'>
        <div className='flex flex-col gap-2'>
          {[1, 2, 3, 4, 5].map((index) => (
            <div key={index} className='flex items-center gap-4'>
              <div className='w-5 h-5 rounded-full bg-gray1 animate-pulse'></div>
              <div className='h-4 w-24 bg-gray1 rounded animate-pulse'></div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-full h-12 bg-gray1 rounded-lg animate-pulse'></div>
    </div>
  );
}
