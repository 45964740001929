import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRef } from 'react';
import useModalStore from 'store/useModalStore';
import { ReactComponent as KoreanFlag } from 'assets/svg/korea-icon.svg';
import { ReactComponent as EnglishFlag } from 'assets/svg/us-icon.svg';
import { getCookie, setCookie } from 'api/cookie';
import { useTranslation } from 'react-i18next';

type Props = {};

const languages = [
  {
    label: '한국어 (Korean)',
    value: 'ko-KR',
    images: KoreanFlag,
  },
  {
    label: 'English',
    value: 'en-US',
    images: EnglishFlag,
  },
];

export default function LanguageModal({}: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const languageInit = getCookie('language') || 'ko-KR';
  const { t, i18n } = useTranslation();
  const { setModals, setOneBtnModal } = useModalStore();

  const handleLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setCookie('language', language);
    setOneBtnModal(t('language.languageChange'), t('language.languageChangeSuccess'), () => setModals('languageModal'));
  };

  return (
    <div className='modal-bg z-[9999999]' ref={bgRef} onClick={() => setModals('languageModal')}>
      <div
        className='flex flex-col justify-between items-center shadow-xl w-[351px] min-h-[250px] rounded-[5px] bg-white overflow-hidden ring-1 ring-black'
        onClick={(e) => e.stopPropagation()}
      >
        <ul className='flex flex-col px-[35px] w-full'>
          <h1 className='text-[15px] text-main7 font-bold border-b border-gray7 w-full text-center py-4'>Language</h1>
          {languages.map((language) => (
            <li
              key={language.value}
              className={`flex items-center justify-center relative  py-4 border-b border-gray7 w-full cursor-pointer ${
                language.value === languageInit ? 'text-main1' : ''
              }`}
              onClick={() => handleLanguage(language.value)}
            >
              <span className='text-[12px] font-bold'>{language.label}</span>
              <language.images className='absolute left-0 w-[20px] h-[20px]' />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
