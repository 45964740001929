import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import { Link, useNavigate } from 'react-router';
import MoreSelect from 'components/common/MoreSlect';
import { MenuResponse } from 'api/admin/menu';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import useModalStore from 'store/useModalStore';
import { errorHandler } from 'lib/error';
import MenuPreview from 'components/preview/menu';
import { useTranslation } from 'react-i18next';
import { getCookie } from 'api/cookie';
import { fetchRestaurant, Restaurant } from 'api/admin/restaurants';

type Props = {};

export default function MenuList({}: Props) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const data: MenuResponse = queryClient.getQueryData(['fetchMenus']);

  const { t } = useTranslation();
  const { setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();

  const restaurantId = getCookie('restaurantId');
  const { data: restaurantData } = useQuery<Restaurant>({
    queryKey: ['fetchRestaurant', restaurantId],
    queryFn: () => fetchRestaurant(restaurantId),
    enabled: !!restaurantId,
  });

  const { mutate: deleteMenuMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const deleteMenu = (menuId: string) => {
    if (!menuId) return;

    deleteMenuMutate(
      {
        method: 'DELETE',
        url: `/admin/menus/${menuId}`,
      },
      {
        onSuccess: () => {
          setOneBtnModal(t('message.alert'), t('menu.메뉴판을삭제하였습니다'), () => {
            queryClient.invalidateQueries({ queryKey: ['fetchMenus'] });
            navigate('/admin/menu');
          });
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  return (
    <>
      {/* contents */}
      <div className='flex-[2] md:pt-[40px] md:px-[45px]'>
        <div className='flex items-center justify-between pb-[48px]'>
          <h2 className='text-xl font-bold '>{t('menu.메뉴판')}</h2>
          <Link
            to={'/admin/menu/create'}
            className='text-[12px] md:text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main1 hover:transition-colors duration-300 hover:strong-pulse'
          >
            {t('menu.새메뉴판만들기')}
          </Link>
        </div>
        <section className='flex flex-col '>
          <ul className='flex flex-col gap-2'>
            {data?.payload.map((item, index) => (
              <li key={item.id} className='flex justify-between items-center'>
                <Link
                  to={`/admin/menu/${item.id}`}
                  className='text-[12px] md:text-[14px] font-semibold cursor-pointer'
                >{`${t(`language.${item.language}`)} ${t('menu.메뉴판')}`}</Link>
                <span className='flex items-center justify-start gap-4'>
                  <GlobalIcon width={20} height={20} />

                  <span className='text-[12px] md:text-[14px]'>{t(`language.${item.language}`)}</span>
                  <span className='cursor-pointer'>
                    <MoreSelect
                      list={[
                        {
                          label: t('menu.삭제하기'),
                          name: t('menu.삭제하기'),
                          onClick: () => {
                            setTwoBtnModal({
                              title: t('message.alert'),
                              contents: t('menu.정말로메뉴판을삭제하시겠습니까'),
                              leftText: t('menu.취소'),
                              rightText: t('menu.삭제'),
                              leftFn: () => setCloseTwoBtnModal(),
                              rightFn: () => {
                                setCloseTwoBtnModal();
                                deleteMenu(item.id);
                              },
                            });
                          },
                        },
                      ]}
                    />
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </section>
      </div>
      {/* preview */}
      <MenuPreview
        data={{
          restaurantName: restaurantData?.payload.name,
          menuList: data?.payload.map((item) => ({ id: item.id, name: item.name, language: item.language })),
        }}
      />
    </>
  );
}
