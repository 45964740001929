import { api } from 'api';

export interface TagResponse {
  payload: string[];
  error: null;
}

export function fetchTags(menuId: string) {
  return api({
    method: 'GET',
    url: `/service/menus/${menuId}/tags`,
  });
}
