import { ChangeEvent, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

type Props = {
  style?: string;
  error?: string;
};

//
const _Input = (
  { style, error, ...rest }: Props & InputHTMLAttributes<HTMLInputElement>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div className='w-full'>
      <input
        ref={ref}
        {...rest}
        className={`${style} w-full border-[1px] border-gray3 rounded-[5px] px-[11px] h-[40px] md:h-[44px] text-[12px] md:text-[14px] placeholder:text-gray2`}
        autoComplete='off'
      />
      {error && <span className='text-red-500 text-[10px] md:text-[12px] mt-1 pl-1'>{error}</span>}
    </div>
  );
};

export default forwardRef(_Input);
