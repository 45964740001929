import Button from 'components/style/Button';
import Input from 'components/style/Input';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useEffect, useRef, useState } from 'react';
import useModalStore from 'store/useModalStore';
import CreateTagModal from './CreateTagModal';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Textarea from 'components/style/Textarea';
import useFile from 'hooks/useMulter';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { errorHandler } from 'lib/error';
import { api, ApiError, ApiProps } from 'api';
import { useTranslation } from 'react-i18next';

const createItemsSchema = (t: (key: string) => string) =>
  z.object({
    name: z.string().min(1, { message: t('menu.메뉴이름은필수입력항목입니다') }),
    image: z.string({
      required_error: t('menu.메뉴이미지는필수입력항목입니다'),
      invalid_type_error: t('menu.메뉴이미지는필수입력항목입니다'),
    }),
    description: z.string().min(1, { message: t('menu.메뉴설명은필수입력항목입니다') }),
    options: z.array(z.object({ name: z.string().optional(), price: z.coerce.number() })),
    tags: z.array(z.string()),
  });

export type MenuFormDataType = z.infer<ReturnType<typeof createItemsSchema>>;

export default function CreateMenuModal() {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { fileMutate, saveFile, imageValidation } = useFile();
  const { modals, createMenuModal, setModals, setOneBtnModal, setCloseCreateMenuModal } = useModalStore();
  const [preview, setPreview] = useState<string | null>(null);
  const [originalData, setOriginalData] = useState<MenuFormDataType | null>(null);

  const methods = useForm<MenuFormDataType>({
    resolver: zodResolver(createItemsSchema(t)),
    defaultValues: {
      tags: [],
      options: [{ name: undefined, price: undefined }],
    },
  });
  const {
    watch,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleClick = () => setCloseCreateMenuModal();

  const addOptions = () => {
    if (formData.options.length < 5) setValue('options', [...formData.options, { name: undefined, price: undefined }]);
  };

  const removeOption = (index: number) => {
    setValue(
      'options',
      formData.options.filter((_, i) => i !== index)
    );
  };

  const removeTags = (index: number) => {
    setValue(
      'tags',
      formData.tags.filter((_, i) => i !== index)
    );
  };

  const { mutate, isPending, isPaused } = useMutation<MenuFormDataType, ApiError, ApiProps>({ mutationFn: api });
  const onSubmit = (data: MenuFormDataType) => {
    const isPost = createMenuModal.item ? false : true;
    let changedFields: MenuFormDataType = {};

    if (!originalData) {
      changedFields = data;
    } else {
      if (data.name !== originalData.name) changedFields.name = data.name;
      if (data.description !== originalData.description) changedFields.description = data.description;
      if (data.image !== originalData.image) changedFields.image = data.image;
      if (data.options !== originalData.options) changedFields.options = data.options;
      if (data.tags !== originalData.tags) changedFields.tags = data.tags;
    }

    mutate(
      {
        method: isPost ? 'POST' : 'PUT',
        url: isPost ? `/admin/items` : `/admin/items/${createMenuModal.item?.id}`,
        data: {
          categoryId: isPost ? createMenuModal.categoryId : undefined,
          name: changedFields.name,
          image: changedFields.image,
          description: changedFields.description,
          options: changedFields.options
            .filter((option) => option.price)
            .map((option) => ({
              ...(option.name && { name: option.name }),
              price: option.price,
            })),
          tags: changedFields.tags,
          menuId: isPost ? createMenuModal.menuId : undefined,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: ['categories'] });
          const successMessage = isPost
            ? t('menu.메뉴가성공적으로생성되었습니다')
            : t('menu.메뉴가성공적으로수정되었습니다');
          setOneBtnModal(t('message.alert'), successMessage, () => {
            setCloseCreateMenuModal();
          });
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };
  const onError = (errors: FieldErrors<MenuFormDataType>) => {
    const errorMsg = Object.values(errors).map((error) => error.message)[0];
    setOneBtnModal(t('message.alert'), errorMsg);
  };

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      const file = files[0];
      const result = imageValidation(file);
      if (result) {
        setOneBtnModal(t('message.alert'), result);
        return;
      }

      const url = URL.createObjectURL(file);
      setPreview(url);

      const formData = await saveFile(e, 'single');

      fileMutate(formData, {
        onSuccess(data, variables, context) {
          setValue('image', data.payload.url);
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
          setPreview(null);
        },
      });
    }
  };

  const formData = watch();
  const image = preview || formData.image || '';

  useEffect(() => {
    if (createMenuModal.item) {
      const initialData = {
        options: createMenuModal.item.options.map((item) => ({
          name: item.name || '',
          price: item.price,
        })),
        tags: createMenuModal.item.tags || [],
        description: createMenuModal.item.description || '',
        image: createMenuModal.item.image || '',
        name: createMenuModal.item.name || '',
      };

      setOriginalData(initialData);
      reset(initialData);
    }
  }, [createMenuModal]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <>
      <FormProvider {...methods}>
        {modals.createTagModal.isOpen && <CreateTagModal menuId={createMenuModal.menuId} />}
        <div className='modal-bg' ref={bgRef}>
          <form
            className='scroll relative shadow-xl md:rounded-2xl bg-white py-6 px-8 border border-gray-100 w-screen h-[100dvh] md:max-h-[80%] md:w-[600px] overflow-y-scroll'
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className='flex items-center justify-between gap-9 mb-12'>
              <h2 className='text-[20px] font-bold'>{t('menu.메뉴생성')}</h2>
              <div className='cursor-pointer' onClick={handleClick}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='size-7'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                  />
                </svg>
              </div>
            </div>

            <div className='flex items-center justify-between gap-9 mb-3'>
              <label className={`text-[14px] font-medium text-nowrap w-[120px]`}>{t('menu.카테고리')}</label>
              <Input
                placeholder={t('menu.카테고리')}
                required
                type='text'
                readOnly
                style='cursor-default'
                value={createMenuModal.categoryName}
              />
            </div>

            <div className='flex items-center justify-between gap-9'>
              <label className={`text-[14px] font-medium text-nowrap w-[120px] after:content-['*'] after:text-red-500`}>
                {t('menu.메뉴이름')}
              </label>
              <Input placeholder={t('menu.메뉴')} required type='text' {...register('name')} />
            </div>

            <div className='mt-10'>
              <h2 className='mb-4 text-[14px] font-bold'>{t('menu.메뉴추가옵션')}</h2>
              {formData.options.map((option, index) => (
                <div
                  key={index}
                  className='relative flex items-center justify-center gap-8 border mb-4 last:mb-0 px-3 pt-5 py-3 rounded-lg'
                >
                  <span
                    className='absolute top-2 right-2 cursor-pointer'
                    hidden={index === 0}
                    onClick={() => removeOption(index)}
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='size-5'>
                      <path d='M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z' />
                    </svg>
                  </span>
                  <div className='flex flex-col gap-2'>
                    <label className='text-[14px] font-medium '>{t('menu.추가옵션명')}</label>
                    <Input placeholder='150g' type='text' required={false} {...register(`options.${index}.name`)} />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <label className="text-[14px] font-medium  after:content-['*'] after:text-red-500">
                      {t('menu.가격')}
                    </label>
                    <Input placeholder='15000' type='number' required={true} {...register(`options.${index}.price`)} />
                  </div>
                </div>
              ))}

              <div className='mt-6'>
                <Button type='button' onClick={addOptions} text={t('menu.메뉴옵션추가')} style='bg-main4 text-white' />
              </div>
            </div>

            <div className='mt-10'>
              <h2 className={`mb-4 text-[14px] font-bold after:content-['*'] after:text-red-500`}>
                {t('menu.메뉴이미지')}
              </h2>
              <label
                className='flex justify-center items-center aspect-video font-[16px] text-gray2 cursor-pointer border border-gray1 rounded-lg w-full bg-cover bg-center'
                style={{
                  backgroundImage: `url(${image})`,
                }}
              >
                {!image ? <span className='font-semibold'>{t('menu.메뉴이미지업로드')}</span> : ''}
                <input type='file' accept='images/*' hidden onChange={onChange} />
              </label>
            </div>

            <div className='mt-10'>
              <div className='flex justify-between items-center mb-4 text-[14px] font-bold'>
                <h2>{t('menu.태그')}</h2>

                <Button
                  type='button'
                  onClick={() => setModals('createTagModal')}
                  text={t('menu.태그추가')}
                  style='bg-main4 text-white max-w-[80px]'
                />
              </div>
              <div className='text-left aspect-video border border-gray1 rounded-lg w-full py-4 px-4'>
                <div className='flex flex-wrap gap-2'>
                  {formData.tags.map((tag, index) => (
                    <span
                      key={index}
                      className='h-[40px] font-[12px] py-2 px-3 border border-black rounded-md flex items-center justify-between gap-2'
                    >
                      {tag}
                      <span className='cursor-pointer' onClick={() => removeTags(index)}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth='1.5'
                          stroke='currentColor'
                          className='size-6'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z'
                          />
                        </svg>
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <div className='mt-10'>
              <h2 className={`mb-4 text-[14px] font-bold after:content-['*'] after:text-red-500`}>
                {t('menu.메뉴설명')}
              </h2>
              <Textarea
                placeholder={t('menu.메뉴설명')}
                style='flex justify-center items-center aspect-video font-[16px] placeholder:text-gray2 placeholder:text-[16px] placeholder:font-semibold border border-gray1 rounded-lg w-full pl-3 pt-3'
                {...register('description')}
              />
            </div>
            <div className='flex items-center justify-between gap-9 mt-16'>
              <Button
                text={isPending ? t('message.processing') : createMenuModal.item ? t('menu.수정') : t('menu.생성')}
                type='submit'
                style={`text-white ${isPending ? 'strong-pulse bg-gray2' : 'bg-main1'}`}
                disabled={isPending}
              />
              <Button text={t('menu.취소')} onClick={handleClick} style='bg-gray2 text-white ' />
            </div>
          </form>
        </div>
      </FormProvider>
    </>
  );
}
