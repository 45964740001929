import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    restaurantName: string;
    menuList: {
      id: string;
      name: string;
      language: string;
    }[];
  };
};

export default function MenuPreview({ data }: Props) {
  const { t } = useTranslation();
  return (
    <section className='pl-8 pt-4 border-l border-gray1 hidden xl:block'>
      <h2 className='text-gray-600 text-[18px] font-bold'>{t('menuPreview.미리보기')}</h2>
      <div className='mt-6 relative'>
        <div className='absolute z-[-1]'>
          <PhonBackground />
        </div>
        <div className='pt-[63px] w-[375px] h-[667px] *:text-[12px] flex flex-col pl-[48px] pr-[56px]'>
          <div className='relative pt-[56px] flex flex-col items-center'>
            <span className='absolute top-[16px] right-[22px] flex items-center gap-2'>
              <InformationCircleIcon width={34} height={34} fill='#2ECC71' />
            </span>
            <span>
              <LogoSvg width={158} hanging={56} />
            </span>
            <h2 className='text-[15px] mt-[42px] font-bold'>{data.restaurantName}</h2>

            <div className='w-full h-[366px] mt-[50px] flex flex-col items-center justify-start gap-[16px] overflow-y-auto'>
              {data.menuList.map((menu, index) => (
                <span
                  key={index}
                  className='w-full rounded-[10px] bg-main7 py-[15px] text-center font-bold text-[15px] text-white'
                >
                  {t(`language.${menu.language}`)}
                </span>
              ))}
              {/* <Link
          to={`/restaurant`}
          className='w-full rounded-[10px] bg-gray1 py-[15px] text-center font-bold text-[15px] text-gray2'
        >
          돌아가기
        </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
