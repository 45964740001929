import MenuBanner from 'components/service/MenuBanner';
import MenuCategory from 'components/service/MenuCategory';
import MenuHeader from 'components/service/MenuHeader';
import MenuList from 'components/service/MenuList';
import MenuSettings from 'components/service/MenuSettings';

type Props = {};

export default function RestaurantMenuBoard({}: Props) {
  return (
    <div className='py'>
      <MenuHeader />
      <MenuBanner />
      <MenuCategory />
      <MenuSettings />
      <MenuList />
    </div>
  );
}
