import { Link, Outlet } from 'react-router';
import SideMenuBar from './SideMenuBar';
import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import Select from './Select';
import { useNavigate } from 'react-router';
import { removeCookie } from 'api/cookie';
import { getUserInfo } from 'lib/userInfo';
import useModalStore from 'store/useModalStore';
import OneBtnModal from '../modal/OneBtnModal';
import TwoBtnModal from '../modal/TwoBtnModal';
import { useState } from 'react';
import { ReactComponent as HamburgerIcon } from 'assets/svg/landing/hamburger-icon.svg';
import HamburgurBar from './HamburgurBar';
type Props = {};

export default function AdminAfterLoginLayout({}: Props) {
  const navigate = useNavigate();
  const userinfo = getUserInfo();
  const { oneBtnModal, twoBtnModal } = useModalStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    await removeCookie('accessToken');
    await removeCookie('refreshToken');
    await removeCookie('restaurantId');
    navigate('/admin/login');
  };
  return (
    <>
      {oneBtnModal.isOpen && (
        <OneBtnModal contents={oneBtnModal.alertMsg} title={oneBtnModal.title} onClick={oneBtnModal.onClick} />
      )}
      {twoBtnModal.isOpen && (
        <TwoBtnModal
          title={twoBtnModal.title}
          contents={twoBtnModal.contents}
          leftText={twoBtnModal.leftText}
          leftFn={twoBtnModal.leftFn}
          rightText={twoBtnModal.rightText}
          rightFn={twoBtnModal.rightFn}
        />
      )}

      <div className='flex flex-col h-[100dvh] px-[20px] 2xl:px-[120px]'>
        <div className='flex items-center justify-between py-[15px] md:py-[30px] border-b-[1px] border-gray1'>
          <div className='flex items-center gap-4'>
            <button onClick={() => setIsMenuOpen(true)} className='lg:hidden'>
              <HamburgerIcon />
            </button>
            <Link to='/'>
              <LogoIcon className='w-[60px] h-[16px] md:w-[88px] md:h-[22px]' />
            </Link>
            <div className='mt-[20px] h-[3px] md:h-[7px] bg-main1  rounded-[2px]' />
          </div>
          <span className='flex items-center gap-4'>
            <GlobalIcon className='w-[20px] md:w-[30px]' />
            <Select
              defaultValue={userinfo?.email}
              list={[
                {
                  label: '로그아웃',
                  value: 'logout',

                  onClick: handleLogout,
                },
              ]}
            />
          </span>
        </div>
        <div className={`flex-1 flex py-[39px] max-w-[1600px] w-full mx-auto 2xl:px-[40px]`}>
          <SideMenuBar />
          <Outlet />
        </div>
      </div>

      {/* mobile - side menu */}
      <div
        className={`fixed top-0 left-0 h-full w-full z-50 transition-all duration-300 ${
          isMenuOpen ? 'visible' : 'invisible'
        }`}
      >
        {/* Backdrop */}
        <div
          className={`menu-backdrop absolute top-0 left-0 w-full h-full bg-black transition-opacity duration-300 ${
            isMenuOpen ? 'opacity-50' : 'opacity-0'
          }`}
          onClick={() => setIsMenuOpen(false)}
        />

        {/* mobile - hamburgur bar */}
        <HamburgurBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      </div>
    </>
  );
}
