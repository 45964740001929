import { ApiError } from 'api';
import { TFunction } from 'react-i18next';

export const ERROR_CODES = {
  E01000: '회원이 이미 존재합니다',
  E01001: '회원이 존재하지 않습니다',
  E01002: '비밀번호가 일치하지 않습니다',
  E01003: '로그인 정보가 존재하지 않습니다',
  E01004: '인증 기록이 존재하지 않습니다',
  E01005: '유효시간이 초과되었습니다',
  E01006: '승인되지 않은 회원입니다',
  E01007: '관리자 권한이 존재하지 않습니다',
  E02000: '존재하지 않는 레스토랑입니다',
  E03000: '이미 존재 하는 메뉴 언어입니다',
  E03001: '존재하지 않는 메뉴입니다',
  E04001: '존재하지 않는 배너입니다',
  E05001: '존재하지 않는 카테고리입니다',
  E06001: '이미 존재하는 태그입니다',
  E06002: '존재하지 않는 태그입니다',
  E07001: '존재하지 않는 상품입니다',
};

export const ERROR_CODE_KEYS = {
  E01000: 'errorCodes.E01000',
  E01001: 'errorCodes.E01001',
  E01002: 'errorCodes.E01002',
  E01003: 'errorCodes.E01003',
  E01004: 'errorCodes.E01004',
  E01005: 'errorCodes.E01005',
  E01006: 'errorCodes.E01006',
  E01007: 'errorCodes.E01007',
  E02000: 'errorCodes.E02000',
  E03000: 'errorCodes.E03000',
  E03001: 'errorCodes.E03001',
  E04001: 'errorCodes.E04001',
  E05001: 'errorCodes.E05001',
  E06001: 'errorCodes.E06001',
  E06002: 'errorCodes.E06002',
  E07001: 'errorCodes.E07001',
};

export function errorHandler(
  t: TFunction,
  error: ApiError,
  setOneBtnModal: (title: string, alertMsg: string, onClick?: () => void) => void
) {
  if (ERROR_CODES[error.error.code]) {
    setOneBtnModal(t('message.alert'), t(ERROR_CODES[error.error.code]));
  } else {
    setOneBtnModal(t('message.alert'), t('errorCodes.ERROR_MESSAGE'));
  }
}
