import i18next from 'i18next';
export const LANGUAGE_KEYS = [
  {
    labelKey: 'language.ko',
    value: 'ko',
  },
  {
    labelKey: 'language.en',
    value: 'en',
  },
  {
    labelKey: 'language.ja',
    value: 'ja',
  },
  {
    labelKey: 'language.zh',
    value: 'zh',
  },
  {
    labelKey: 'language.fr',
    value: 'fr',
  },
  {
    labelKey: 'language.de',
    value: 'de',
  },
  {
    labelKey: 'language.af',
    value: 'af',
  },
  {
    labelKey: 'language.sq',
    value: 'sq',
  },
  {
    labelKey: 'language.am',
    value: 'am',
  },
  {
    labelKey: 'language.ar',
    value: 'ar',
  },
  {
    labelKey: 'language.hy',
    value: 'hy',
  },
  {
    labelKey: 'language.az',
    value: 'az',
  },
  {
    labelKey: 'language.bn',
    value: 'bn',
  },
  {
    labelKey: 'language.bs',
    value: 'bs',
  },
  {
    labelKey: 'language.bg',
    value: 'bg',
  },
  {
    labelKey: 'language.ca',
    value: 'ca',
  },
  {
    labelKey: 'language.hr',
    value: 'hr',
  },
  {
    labelKey: 'language.cs',
    value: 'cs',
  },
  {
    labelKey: 'language.da',
    value: 'da',
  },
  {
    labelKey: 'language.nl',
    value: 'nl',
  },

  {
    labelKey: 'language.et',
    value: 'et',
  },
  {
    labelKey: 'language.fa',
    value: 'fa',
  },
  {
    labelKey: 'language.tl',
    value: 'tl',
  },
  {
    labelKey: 'language.fi',
    value: 'fi',
  },
  {
    labelKey: 'language.ka',
    value: 'ka',
  },
  {
    labelKey: 'language.el',
    value: 'el',
  },
  {
    labelKey: 'language.gu',
    value: 'gu',
  },
  {
    labelKey: 'language.ht',
    value: 'ht',
  },
  {
    labelKey: 'language.ha',
    value: 'ha',
  },
  {
    labelKey: 'language.he',
    value: 'he',
  },
  {
    labelKey: 'language.hi',
    value: 'hi',
  },
  {
    labelKey: 'language.hu',
    value: 'hu',
  },
  {
    labelKey: 'language.is',
    value: 'is',
  },
  {
    labelKey: 'language.id',
    value: 'id',
  },
  {
    labelKey: 'language.ga',
    value: 'ga',
  },
  {
    labelKey: 'language.it',
    value: 'it',
  },
  {
    labelKey: 'language.kn',
    value: 'kn',
  },
  {
    labelKey: 'language.kk',
    value: 'kk',
  },
  {
    labelKey: 'language.lv',
    value: 'lv',
  },
  {
    labelKey: 'language.lt',
    value: 'lt',
  },
  {
    labelKey: 'language.mk',
    value: 'mk',
  },
  {
    labelKey: 'language.ms',
    value: 'ms',
  },
  {
    labelKey: 'language.ml',
    value: 'ml',
  },
  {
    labelKey: 'language.mt',
    value: 'mt',
  },
  {
    labelKey: 'language.mr',
    value: 'mr',
  },
  {
    labelKey: 'language.mn',
    value: 'mn',
  },
  {
    labelKey: 'language.no',
    value: 'no',
  },
  {
    labelKey: 'language.ps',
    value: 'ps',
  },
  {
    labelKey: 'language.pl',
    value: 'pl',
  },
  {
    labelKey: 'language.pt',
    value: 'pt',
  },
  {
    labelKey: 'language.pa',
    value: 'pa',
  },
  {
    labelKey: 'language.ro',
    value: 'ro',
  },
  {
    labelKey: 'language.ru',
    value: 'ru',
  },
  {
    labelKey: 'language.sr',
    value: 'sr',
  },
  {
    labelKey: 'language.si',
    value: 'si',
  },
  {
    labelKey: 'language.sk',
    value: 'sk',
  },
  {
    labelKey: 'language.sl',
    value: 'sl',
  },
  {
    labelKey: 'language.so',
    value: 'so',
  },
  {
    labelKey: 'language.es',
    value: 'es',
  },
  {
    labelKey: 'language.sw',
    value: 'sw',
  },
  {
    labelKey: 'language.sv',
    value: 'sv',
  },
  {
    labelKey: 'language.ta',
    value: 'ta',
  },
  {
    labelKey: 'language.te',
    value: 'te',
  },
  {
    labelKey: 'language.th',
    value: 'th',
  },
  {
    labelKey: 'language.tr',
    value: 'tr',
  },
  {
    labelKey: 'language.uk',
    value: 'uk',
  },
  {
    labelKey: 'language.ur',
    value: 'ur',
  },
  {
    labelKey: 'language.uz',
    value: 'uz',
  },
  {
    labelKey: 'language.vi',
    value: 'vi',
  },
  {
    labelKey: 'language.cy',
    value: 'cy',
  },
];

/* 영어 강제 번역 */
export const getLanguageDisplay = (language: string) => {
  return i18next.t(`language.${language}`, { lng: 'en-US' });
};
