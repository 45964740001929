import { create } from 'zustand';

interface UseTagsStore {
  state: {
    tags: string[];
  };
  setState: (tags: string[]) => void;
}

const useTagsStore = create<UseTagsStore>((set) => ({
  state: { tags: [] },
  setState: (tags) => set(() => ({ state: { tags } })),
}));

export default useTagsStore;
