import CustomButton from 'components/style/Button';
import Select from 'components/common/Select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { getCookie } from 'api/cookie';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useModalStore from 'store/useModalStore';
import { errorHandler } from 'lib/error';
import { LANGUAGE_KEYS } from 'lib/language';
import MenuPreview from 'components/preview/menu';
import { fetchMenus, MenuResponse } from 'api/admin/menu';
import { useTranslation } from 'react-i18next';
import { fetchRestaurant, Restaurant } from 'api/admin/restaurants';

type Props = {};

export default function Unlisted({}: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const languages = useMemo(
    () =>
      LANGUAGE_KEYS.map((lang) => ({
        label: t(lang.labelKey),
        value: lang.value,
      })),
    [t]
  );
  const { setOneBtnModal } = useModalStore();
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: '',
    value: '',
  });
  const restaurantId = getCookie('restaurantId');
  const { data: restaurantData } = useQuery<Restaurant>({
    queryKey: ['fetchRestaurant', restaurantId],
    queryFn: () => fetchRestaurant(restaurantId),
    enabled: !!restaurantId,
  });
  const { data: menuList, isLoading: menuListLoading } = useQuery<MenuResponse, ApiError>({
    queryKey: ['fetchMenus'],
    queryFn: fetchMenus,
  });

  const { mutate: createMenuMutate, isPending } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const createMenu = () => {
    const { label, value } = selectedLanguage;
    if (!label || !value) {
      return setOneBtnModal(t('message.alert'), t('menu.언어를선택해주세요'), () => {});
    }
    createMenuMutate(
      {
        method: 'POST',
        url: `/admin/restaurants/${restaurantId}/menus`,
        data: {
          name: label,
          language: value,
        },
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({ queryKey: ['fetchMenus'] });
          setOneBtnModal(t('message.alert'), t('menu.메뉴판을생성하였습니다'), () => navigate('/admin/menu'));
        },
        onError: (error, variables, context) => {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  return (
    <>
      {/* contents */}
      <section className='flex flex-col  flex-[2] md:pt-[40px] md:px-[60px]'>
        <h2 className='text-xl font-bold mb-[48px]'>{t('menu.메뉴판생성')}</h2>
        <div className='flex gap-7'>
          <div className='flex flex-col items-center justify-center border w-full aspect-video rounded-md'>
            {/* <span className='text-[14px] text-gray2 font-semibold'>수동작성</span> */}
            <Select
              list={languages}
              placeholder={t('menu.언어선택')}
              style={'text-[14px] text-gray2 font-semibold mt-2'}
              onChange={(value, label) => setSelectedLanguage({ label, value })}
            />
          </div>

          <label
            className='flex items-center justify-center border w-full aspect-video rounded-md cursor-pointer'
            onClick={() => setOneBtnModal(t('message.alert'), t('menu.추후업데이트예정입니다'))}
          >
            <span className='text-[14px] text-gray2 font-semibold'>{t('menu.엑셀업로드')}</span>
            {/* <input hidden type={'file'} accept='.xlsx' /> */}
          </label>
        </div>

        <CustomButton
          text={isPending ? t('message.processing') : t('menu.메뉴판생성')}
          style={`${isPending ? 'bg-gray1 text-white' : 'bg-black text-white'} mt-[60px]`}
          onClick={createMenu}
          disabled={isPending}
        />
      </section>
      {/* preview */}
      <MenuPreview
        data={{
          restaurantName: restaurantData?.payload.name,
          menuList: [
            ...(menuList?.payload.map((item) => ({ id: item.id, name: item.name, language: item.language })) || []),
            ...(selectedLanguage.label
              ? [
                  {
                    id: '1',
                    name: selectedLanguage.label,
                    language: selectedLanguage.value,
                  },
                ]
              : []),
          ],
        }}
      />
    </>
  );
}
