import { ReactComponent as LogoIcon } from 'assets/svg/logo.svg';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState, useTransition } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from './Select';
import { useQuery } from '@tanstack/react-query';
import { fetchRestaurants, Restaurants } from 'api/admin/restaurants';
import { getUserInfo } from 'lib/userInfo';
import { fetchMenus, MenuResponse } from 'api/admin/menu';
import { ApiError } from 'api';
import { QrCodeIcon } from '@heroicons/react/24/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import { CubeIcon } from '@heroicons/react/24/solid';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useTranslation } from 'react-i18next';

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
};

export default function HamburgurBar({ isMenuOpen, setIsMenuOpen }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const userInfo = getUserInfo();
  const [restaurantList, setRestaurantList] = useState<{ value: string; label: string }[]>([]);

  const { data, isLoading } = useQuery<Restaurants>({
    queryKey: ['restaurantList'],
    queryFn: fetchRestaurants,
  });

  const { data: menuList, isLoading: menuListLoading } = useQuery<MenuResponse, ApiError>({
    queryKey: ['fetchMenus'],
    queryFn: fetchMenus,
  });

  useEffect(() => {
    if (data) {
      setRestaurantList(
        data.payload.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [data]);

  useEffect(() => {
    if (location) {
      setIsMenuOpen(false);
    }
  }, [location]);

  return (
    <div
      className={`side-menu absolute top-0 left-0 w-full h-full bg-white shadow-lg transition-transform duration-300 ${
        isMenuOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className='p-5'>
        <div className='flex justify-between items-center mb-8'>
          <LogoIcon width={60} height={10} />
          <button onClick={() => setIsMenuOpen(false)}>
            <XMarkIcon className='w-6 h-6' />
          </button>
        </div>

        <ul className='flex flex-col gap-[30px] *:text-black *:text-[15px] *:leading-[32px] '>
          <>
            <div className={`${location?.pathname.includes('dashboard') && 'text-main1'} flex items-center gap-2`}>
              <QrCodeIcon className={`${location?.pathname.includes('dashboard') && 'text-main1'} w-6 h-6`} />
              <Select
                clicked={location?.pathname.includes('dashboard')}
                list={restaurantList}
                defaultValue={restaurantList[0]?.label}
                onChange={() => navigate(`/admin/dashboard/${restaurantList[0]?.value}`)}
                style={`${
                  location?.pathname.includes('dashboard') && 'text-main1'
                } text-[15px] leading-[32px] hover:transition-colors hover:duration-200 hover:text-main2 *:hover:stroke-main2 *:hover:transition-colors *:hover:duration-200`}
                arrowWidth={14}
                arrowHeight={12}
              />
            </div>
            {/* 메뉴 */}
            <div>
              <Link
                to={'/admin/menu'}
                className={`${
                  location?.pathname.includes('menu') && 'text-main1'
                } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
              >
                <Bars3CenterLeftIcon className='w-6 h-6' />
                <span>{t('nav.메뉴')}</span>
              </Link>
              <ul className='flex flex-col gap-2 pl-3 mt-2'>
                {menuList?.payload.map((menu, index) => (
                  <Link
                    to={`/admin/menu/${menu.id}`}
                    key={menu.id}
                    className={`${
                      location?.pathname.includes(menu.id) && 'text-main1'
                    } hover:text-main2 hover:transition-colors hover:duration-200 cursor-pointer flex items-center gap-2`}
                  >
                    <CubeIcon className='w-6 h-6' />
                    <span>{t(`language.${menu.language}`)}</span>
                  </Link>
                ))}
              </ul>
            </div>

            {/* 설정 */}
            <Link
              to={'/admin/settings'}
              className={`${
                location?.pathname.includes('settings') && 'text-main1'
              } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
            >
              <Cog6ToothIcon className={`${location?.pathname.includes('settings') && 'text-main1'} w-6 h-6`} />
              <span className={`${location?.pathname.includes('settings') && 'text-main1'}`}>{t('nav.설정')}</span>
            </Link>
            {/* 배너 관리 */}
            <Link
              to={'/admin/banners'}
              className={`${
                location?.pathname.includes('banners') && 'text-main1'
              } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
            >
              <ClipboardDocumentListIcon
                className={`${location?.pathname.includes('banners') && 'text-main1'} w-6 h-6`}
              />
              <span className={`${location?.pathname.includes('banners') && 'text-main1'}`}>{t('nav.배너관리')}</span>
            </Link>
            {/*  입점사 관리  */}
            {userInfo?.role === 'admin' ? (
              <Link
                to={'/admin/partners'}
                className={`${
                  location?.pathname.includes('partners') && 'text-main1'
                } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
              >
                <UserCircleIcon className={`${location?.pathname.includes('partners') && 'text-main1'} w-6 h-6`} />
                <span className={`${location?.pathname.includes('partners') && 'text-main1'}`}>
                  {t('nav.입점사관리')}
                </span>
              </Link>
            ) : (
              ''
            )}
          </>
        </ul>
      </div>
    </div>
  );
}
