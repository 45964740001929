import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from './Select';
import { useQuery } from '@tanstack/react-query';
import { fetchRestaurants, Restaurants } from 'api/admin/restaurants';
import { getUserInfo } from 'lib/userInfo';
import { fetchMenus, MenuResponse } from 'api/admin/menu';
import { ApiError } from 'api';
import { QrCodeIcon } from '@heroicons/react/24/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/solid';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid';
import { CubeIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function SideMenuBar({}: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = getUserInfo();
  const { t } = useTranslation();
  const [restaurantList, setRestaurantList] = useState<{ value: string; label: string }[]>([]);

  const { data, isLoading } = useQuery<Restaurants>({
    queryKey: ['restaurantList'],
    queryFn: fetchRestaurants,
  });

  const { data: menuList, isLoading: menuListLoading } = useQuery<MenuResponse, ApiError>({
    queryKey: ['fetchMenus'],
    queryFn: fetchMenus,
  });

  useEffect(() => {
    if (data) {
      setRestaurantList(
        data.payload.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [data]);

  return (
    <div className='border-r border-gray1 h-full min-w-[220px] pr-4 hidden lg:block'>
      <ul className='flex flex-col gap-[30px] text-gray2 text-[18px] font-bold '>
        <>
          <div
            className={`${location?.pathname.includes('dashboard') && 'text-main1'} flex items-center gap-2`}
            // to={"/admin/dashboard"}
          >
            <QrCodeIcon className='w-6 h-6' />
            <Select
              clicked={location?.pathname.includes('dashboard')}
              list={restaurantList}
              defaultValue={restaurantList[0]?.label}
              onChange={() => navigate(`/admin/dashboard/${restaurantList[0]?.value}`)}
              style='hover:transition-colors hover:duration-200 hover:text-main2 *:hover:stroke-main2 *:hover:transition-colors *:hover:duration-200 md:text-[18px] '
            />
            {/* 레스토랑 이름 */}
          </div>
          <div>
            <Link
              className={`${
                location?.pathname.includes('menu') && 'text-main1'
              } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
              to={'/admin/menu'}
            >
              <Bars3CenterLeftIcon className='w-6 h-6' />
              <span>{t('nav.메뉴')}</span>
            </Link>
            <ul className='flex flex-col gap-2 pl-3 mt-2'>
              {menuList?.payload.map((menu, index) => (
                <Link
                  to={`/admin/menu/${menu.id}`}
                  key={menu.id}
                  className={`${
                    location?.pathname.includes(menu.id) && 'text-main1'
                  } hover:text-main2 hover:transition-colors hover:duration-200 cursor-pointer flex items-center gap-2 text-[16px]`}
                >
                  <CubeIcon className='w-6 h-6' />
                  <span>{t(`language.${menu.language}`)}</span>
                </Link>
              ))}
            </ul>
          </div>

          <Link
            className={`${
              location?.pathname.includes('settings') && 'text-main1'
            } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
            to={'/admin/settings'}
          >
            <Cog6ToothIcon className='w-6 h-6' />
            <span>{t('nav.설정')}</span>
          </Link>
          <Link
            className={`${
              location?.pathname.includes('banners') && 'text-main1'
            } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
            to={'/admin/banners'}
          >
            <ClipboardDocumentListIcon className='w-6 h-6' />
            <span>{t('nav.배너관리')}</span>
          </Link>

          {userInfo?.role === 'admin' ? (
            <Link
              className={`${
                location?.pathname.includes('partners') && 'text-main1'
              } hover:transition-colors hover:duration-200 hover:text-main2 flex items-center gap-2`}
              to={'/admin/partners'}
            >
              <UserCircleIcon className='w-6 h-6' />
              <span>{t('nav.입점사관리')}</span>
            </Link>
          ) : (
            ''
          )}
        </>
      </ul>
    </div>
  );
}
