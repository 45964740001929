import { GetRestaurantInfo } from 'api/service/restaurant';
import { create } from 'zustand';

interface UseRestaurantInfoStore {
  restaurantInfo: GetRestaurantInfo;
  setRestaurantInfo: (state: GetRestaurantInfo) => void;
}

const useRestaurantInfoStore = create<UseRestaurantInfoStore>((set) => ({
  restaurantInfo: {
    id: '',
    name: '',
    phone: '',
    managerPhone: '',
    logo: '',
    currencyUnit: '',
    addressUrl: '',
    address: '',
    originMarking: '',
    snsUrl: '',
    businessHours: '',
    memberId: '',
  },
  setRestaurantInfo: (restaurantInfo) => set(() => ({ restaurantInfo })),
}));

export default useRestaurantInfoStore;
