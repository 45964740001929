import useBodyOverflow from 'hooks/useBodyOverflow';
import React, { useEffect, useRef } from 'react';
import useModalStore from 'store/useModalStore';

export interface TwoBtnModalProps {
  leftText: string;
  leftFn: () => void;
  rightText: string;
  rightFn: () => void;
  title: string;
  contents: string;
}

export default function TwoBtnModal({ leftText, leftFn, rightText, rightFn, title, contents }: TwoBtnModalProps) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setCloseTwoBtnModal } = useModalStore();
  const handleClick = () => setCloseTwoBtnModal();

  return (
    <div className='modal-bg z-[999999]' ref={bgRef} onClick={handleClick}>
      <div
        className='flex flex-col justify-between items-center shadow-xl w-[322px] h-[260px] rounded-[25px] bg-white overflow-hidden'
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className='flex items-center justify-center h-[55px] text-[16px] w-full text-gray2 border-b border-gray1'>
          {title}
        </h2>
        <span className='text-[14px] text-center leading-8 whitespace-pre'>{contents}</span>
        <div className='flex justify-between items-center w-full h-[57px]'>
          <button className='w-full h-full bg-main3 text-white font-bold' onClick={leftFn}>
            {leftText}
          </button>
          <button className='w-full h-full bg-gray2 text-white font-bold' onClick={rightFn}>
            {rightText}
          </button>
        </div>
      </div>
    </div>
  );
}
