import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';
import MenuHeaderPreview from './menuHeader';
import MenuBannerPreview from './menuBanner';
import MenuListPreview from './menuList';
import MenuCategoryPreview from './menuCategory';
import { GetServiceCategory } from 'api/service/category';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    restaurantName: string;
    menuName: string;
    language: string;
    categories: GetServiceCategory[];
  };
};

export default function MenuDetailPreview({ data }: Props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    id: 'all',
    name: '전체',
  });
  return (
    <section className='pl-8 pt-4 border-l border-gray1 hidden xl:block'>
      <h2 className='text-gray-600 text-[18px] font-bold'>{t('menuPreview.미리보기')}</h2>
      <div className='mt-6 relative'>
        <div className='absolute z-[-1]'>
          <PhonBackground />
        </div>
        <div className='pt-[63px] w-[375px] h-[667px] *:text-[12px] flex flex-col pl-[48px] pr-[56px]'>
          <div className='py'>
            <MenuHeaderPreview restaurantName={data.restaurantName} menuName={t(`language.${data.language}`)} />
            <MenuBannerPreview />
            <MenuCategoryPreview state={state} setState={setState} categoryList={data.categories} />
            <MenuListPreview
              name={state.name}
              items={data.categories.find((category) => category.id === state.id)?.items || data.categories?.[0]?.items}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
