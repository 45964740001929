import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';

type Props = {
  style?: string;
  error?: string;
};

const _Textarea = (
  { style, error, ...rest }: Props & TextareaHTMLAttributes<HTMLTextAreaElement>,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  return (
    <>
      <textarea
        ref={ref}
        {...rest}
        className={`w-full border-[1px] border-gray3 rounded-[5px] pl-[11px] pt-[11px] text-[12px] md:text-[14px] placeholder:text-[14px] placeholder:text-gray2 ${style}`}
        autoComplete='off'
      />
      {error && <span className='text-red-500 text-[10px] md:text-[12px] mt-1 pl-1'>{error}</span>}
    </>
  );
};

export default forwardRef(_Textarea);
