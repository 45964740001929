import { useQuery } from '@tanstack/react-query';
import { getServiceCategoryList, GetServiceCategoryList } from 'api/service/category';
import { useParams } from 'react-router';
import useCategoryStore from 'store/service/useCategoryStore';
import CategorySkeleton from './skeleton/category';
import { LoadingPromise } from 'lib/loading';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function MenuCategory({}: Props) {
  const { state, setState } = useCategoryStore();
  const { menuId } = useParams();
  const { t } = useTranslation();

  const { data: categoryList, isLoading: categoryListLoading } = useQuery<GetServiceCategoryList>({
    queryKey: ['service/category', menuId],
    queryFn: async () => {
      await LoadingPromise(500);
      return getServiceCategoryList(menuId);
    },
    enabled: !!menuId,
  });
  if (categoryListLoading) {
    return <CategorySkeleton />;
  }

  return (
    <div className='mt-[18px]'>
      <div className='scroll w-full px-5 flex items-center overflow-x-scroll pb-[18px]'>
        {[{ id: 'all', name: '전체' }, ...categoryList?.payload].map((category, categoryIndex) => (
          <div className='relative pr-5' key={category.id}>
            <div className='absolute top-[2px] h-[1px] bg-gray1 w-full' />
            <span className='flex flex-col items-center justify-center gap-[18px]'>
              <div
                className={`w-full h-[6px] rounded-[2px] z-10 ${state.id !== category.id ? 'bg-none' : 'bg-main1'} `}
              />
              <span
                key={category.id}
                onClick={(e) => setState(category)}
                className={`text-[16px] text-nowrap ${
                  state.id === category.id ? 'text-main7 font-bold' : 'text-gray2 font-normal '
                } `}
              >
                {category.name === '전체' ? t('service.전체') : category.name}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
