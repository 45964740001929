import useCategoryStore from 'store/service/useCategoryStore';
import { Link, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { GetItems, getServiceCategoryList, GetServiceCategoryList } from 'api/service/category';
import { useEffect, useState } from 'react';
import { getMaxPrice, getMinPrice, hasDifferentPrices } from 'lib/meun';
import ItemListSkeleton from './skeleton/itemList';
import { LoadingPromise } from 'lib/loading';
import useTagsStore from 'store/service/useTagsStore';
import useRestaurantInfoStore from 'store/service/useRestaurantInfo';
import { useTranslation } from 'react-i18next';

export default function MenuList() {
  const { state } = useCategoryStore();
  const { restaurantInfo } = useRestaurantInfoStore();
  const { t } = useTranslation();
  const { state: tagsState } = useTagsStore();
  const { menuId, restaurantId } = useParams();
  const [items, setItems] = useState<GetItems[]>();
  const { data: categoryList, isLoading: categoryListLoading } = useQuery<GetServiceCategoryList>({
    queryKey: ['service/category', menuId],
    queryFn: async () => {
      await LoadingPromise(500);
      return getServiceCategoryList(menuId);
    },
    enabled: !!menuId,
  });

  const filterItemsByTags = (items: GetItems[]) => {
    return tagsState.tags.length > 0
      ? items.filter((item) => tagsState.tags.some((tag) => item.tags.includes(tag)))
      : items;
  };

  useEffect(() => {
    if (!categoryList) return;

    const targetCategory = categoryList.payload.find((item) => item.id === state.id);
    const itemsToFilter = targetCategory
      ? targetCategory.items
      : categoryList.payload.flatMap((category) => category.items);

    setItems(filterItemsByTags(itemsToFilter));
  }, [categoryList, state, tagsState]);

  if (categoryListLoading) {
    return <ItemListSkeleton />;
  }

  return (
    <div className='px-5'>
      <h3 className='text-base font-semibold mt-[13px] mb-[10px]'>
        {state.name === '전체' ? t('service.전체') : state.name}
      </h3>

      {items?.map((item) => (
        <Link
          to={`/restaurant/${restaurantId}/menus/${menuId}/detail/${item.id}`}
          key={item.id}
          className='flex items-center mb-5'
        >
          <div className='bg-gray1 overflow-hidden rounded-[15px] aspect-[100/107] min-w-[100px] max-w-[100px] w-full mr-[53px]'>
            <img src={item.image} alt={item.name} className='w-full h-full object-cover' />
          </div>
          <div className=''>
            <h2 className='text-base font-bold mb-3'>{item.name}</h2>
            {hasDifferentPrices(item.options) ? (
              <div className='text-base text-main6 font-bold mb-3'>
                <span className='leading-[9.42px] bg-yellow2 rounded-[5.5px]'>
                  {`${Number(getMinPrice(item.options)).toLocaleString()} ${
                    restaurantInfo.currencyUnit ? restaurantInfo.currencyUnit : '원'
                  }`}
                </span>
                <span>{` ~ ${Number(getMaxPrice(item.options)).toLocaleString()} ${
                  restaurantInfo.currencyUnit ? restaurantInfo.currencyUnit : '원'
                }`}</span>
              </div>
            ) : (
              <span className='text-base text-main6 font-bold mb-3 leading-[9.42px] bg-yellow2 rounded-[5.5px]'>{`${Number(
                getMaxPrice(item.options)
              ).toLocaleString()} ${restaurantInfo.currencyUnit ? restaurantInfo.currencyUnit : '원'}`}</span>
            )}

            <p className='line-clamp-2 text-xs font-light'>{item.description}</p>
          </div>
        </Link>
      ))}
    </div>
  );
}
