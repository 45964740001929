import { useMutation, useQuery } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { fetchPartners, PartnersResponse } from 'api/admin/partners';
import Loader from 'components/common/Loader';
import { errorHandler } from 'lib/error';
import { useTranslation } from 'react-i18next';
import useModalStore from 'store/useModalStore';

type Props = {};

export default function Partners({}: Props) {
  const { t } = useTranslation();

  const { data, isLoading, refetch } = useQuery<PartnersResponse>({
    queryKey: ['partners'],
    queryFn: fetchPartners,
  });

  const { setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();

  const { mutate: approvePartner } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const handleApprove = (memberId: string) => {
    setTwoBtnModal({
      title: t('partners.입점사승인'),
      contents: t('partners.입점사를승인하시겠습니까'),
      leftText: t('partners.취소'),
      rightText: t('partners.승인'),
      leftFn: setCloseTwoBtnModal,
      rightFn: () =>
        approvePartner(
          {
            method: 'PATCH',
            url: `/admin/members/${memberId}/approval`,
          },
          {
            onSuccess: () => {
              setOneBtnModal(t('partners.승인완료'), t('partners.입점사승인완료'), () => {
                refetch();
                setCloseTwoBtnModal();
              });
            },
            onError(error, variables, context) {
              errorHandler(t, error, setOneBtnModal);
            },
          }
        ),
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='w-full flex flex-col md:px-[20px]'>
      <h2 className='text-xl font-bold mb-[32px] md:mb-[48px]'>{t('partners.입점사관리')}</h2>
      <section className='flex flex-col gap-4 md:bg-neutral-50 pb-[30px] rounded-xl h-full md:overflow-scroll md:h-[900px]'>
        {/* title */}
        <li className='flex-row justify-between items-center pb-[25px] font-bold *:text-center hidden lg:flex sticky top-0 bg-purple2 text-white pt-[30px]'>
          <span className='flex-[0.5]'>{t('partners.번호')}</span>
          <span className='flex-[1.2]'>{t('partners.아이디')}</span>
          <span className='flex-1'>{t('partners.레스토랑이름')}</span>
          <span className='flex-1'>{t('partners.레스토랑전화번호')}</span>
          <span className='flex-1'>{t('partners.담당자전화번호')}</span>
          <span className='flex-1'>{t('partners.입점신청')}</span>
        </li>

        {/* contents */}
        <ul className='flex flex-col gap-4'>
          {data?.payload.map((member, index) => (
            <li
              key={index}
              className='flex justify-between items-center pb-[25px] border-b border-gray1 last:border-b-0'
            >
              {/* mobile title */}
              <li className='flex flex-col gap-[2px] lg:flex-row justify-start items-start font-bold *:text-center lg:hidden'>
                <span className='flex-[0.5]'>{t('partners.번호')}</span>
                <span className='flex-[1.2]'>{t('partners.아이디')}</span>
                <span className='flex-1'>{t('partners.레스토랑이름')}</span>
                <span className='flex-1'>{t('partners.레스토랑전화번호')}</span>
                <span className='flex-1'>{t('partners.담당자전화번호')}</span>
                <span className='flex-1'>{t('partners.입점신청')}</span>
              </li>

              <li
                key={index}
                className='flex flex-col gap-[2px] lg:flex-row justify-between items-end text-gray-500 *:text-center md:w-full'
              >
                <span className='flex-[0.5] font-bold'>{index + 1}</span>
                <span className='flex-[1.2]'>{member.email}</span>
                <span className='flex-1'>{member.restaurants[0]?.name || '-'}</span>
                <span className='flex-1'>{member.restaurants[0]?.phone || '-'}</span>
                <span className='flex-1'>{member.restaurants[0]?.managerPhone || '-'}</span>
                <span className='flex-1'>
                  <button
                    onClick={() => {
                      if (member.isApproved) return;
                      handleApprove(member.id);
                    }}
                    className={`${
                      member.isApproved
                        ? 'bg-gray-500 text-white cursor-not-allowed'
                        : 'bg-main1 text-white cursor-pointer hover:bg-main2'
                    }  px-[10px] py-[5px] rounded-md `}
                  >
                    {member.isApproved ? t('partners.승인완료') : t('partners.승인처리')}
                  </button>
                </span>
              </li>
            </li>
          ))}
        </ul>
      </section>
      {/* <div className='mt-[50px]'>
        <Pagination
          totalItems={150}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div> */}
    </div>
  );
}
