import CustomButton from 'components/style/Button';
import CustomInput from 'components/style/Input';
import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useModalStore from 'store/useModalStore';
import { MenuFormDataType } from './CreateMenuModal';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { errorHandler } from 'lib/error';
import { useTranslation } from 'react-i18next';

type Props = {
  menuId: string;
};

export default function CreateTagModal({ menuId }: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setModals, setOneBtnModal } = useModalStore();
  const handleClick = () => setModals('createTagModal');
  const [tag, setTag] = useState<string>();
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<MenuFormDataType>();

  const { mutate, isPending } = useMutation<any, ApiError, ApiProps>({ mutationFn: api });
  const createTag = () => {
    const tags = getValues('tags');
    setValue('tags', [...tags, tag]);
    handleClick();
    // TODO: 태그 추가 API 점검 필요
    // mutate(
    //   {
    //     method: 'POST',
    //     url: `/admin/menus/${menuId}/tags`,
    //     data: {
    //       name: tag,
    //     },
    //   },
    //   {
    //     onSuccess(data, variables, context) {
    //       const tags = getValues('tags');
    //       setValue('tags', [...tags, tag]);
    //       handleClick();
    //     },
    //     onError(error, variables, context) {
    //       errorHandler(t,error, setOneBtnModal);
    //     },
    //   }
    // );
  };

  return (
    <div className='modal-bg z-[999999]' ref={bgRef} onClick={handleClick}>
      <div
        className='relative shadow-xl rounded-2xl w-[360px] bg-white py-4 px-4 border border-gray-100'
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className='text-[16px] font-semibold'>{t('tag.태그생성')}</h2>
        <div className='flex flex-col gap-2 mt-8'>
          <label className='text-[14px] font-medium text-nowrap'>{t('tag.이름')}</label>
          <CustomInput
            placeholder={t('tag.태그')}
            required
            type='text'
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />
        </div>

        <div className='flex items-center justify-between gap-9 mt-12'>
          <CustomButton
            text={isPending ? t('menu.처리중') : t('menu.추가')}
            onClick={createTag}
            style={`text-white ${isPending ? 'strong-pulse bg-gray2' : 'bg-main3'}`}
            disabled={isPending}
          />
          <CustomButton text={t('menu.취소')} onClick={handleClick} style='bg-gray2 text-white ' />
        </div>
      </div>
    </div>
  );
}
