import Button from 'components/style/Button';
import Input from 'components/style/Input';
import Textarea from 'components/style/Textarea';
import { getCookie, removeCookie, setCookie } from 'api/cookie';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchRestaurant, Restaurant } from 'api/admin/restaurants';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import validator from 'validator';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import useFile from 'hooks/useMulter';
import { api, ApiError, ApiProps } from 'api';
import { errorHandler } from 'lib/error';
import useModalStore from 'store/useModalStore';
import SettingsPreview from 'components/preview/settings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type Props = {};

const createSettingSchema = (t: (key: string) => string) =>
  z.object({
    name: z.string().trim(),
    phone: z.string().trim(),
    managerPhone: z
      .string()
      .trim()
      .refine((phone) => validator.isMobilePhone(phone, 'ko-KR'), {
        message: t('validation.invalidManagerPhone'),
      })
      .optional(),
    logo: z.string().trim().optional(),
    currencyUnit: z.string().trim().optional(),
    addressUrl: z
      .string()
      .trim()
      .refine(
        (url) => {
          if (url.length === 0) return true;
          return validator.isURL(url);
        },
        {
          message: t('validation.invalidSnsUrl'),
        }
      )
      .optional(),
    address: z.string().trim().optional(),
    originMarking: z.string().trim().optional(),
    snsUrl: z
      .string()
      .trim()
      .refine(
        (url) => {
          if (url.length === 0) return true;
          return validator.isURL(url);
        },
        {
          message: t('validation.invalidSnsUrl'),
        }
      )
      .optional(),
    businessHours: z.string().trim().optional(),
  });

interface RestaurantResponse {
  error: null;
  payload: null;
}
export default function Settings({}: Props) {
  const restaurantId = getCookie('restaurantId');
  const [preview, setPreview] = useState<string | null>(null);
  const { setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();
  const { t } = useTranslation();
  const settingSchema = createSettingSchema(t);
  const { mutate: setRestaurant, isPending } = useMutation<RestaurantResponse, ApiError, ApiProps>({
    mutationFn: api,
  });
  const { fileMutate, saveFile, imageValidation } = useFile();

  const { data, error, isLoading } = useQuery<Restaurant>({
    queryKey: ['restaurantDetail', restaurantId],
    queryFn: () => fetchRestaurant(restaurantId),
    enabled: !!restaurantId,
  });

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<z.infer<typeof settingSchema>>({
    resolver: zodResolver(settingSchema),
    defaultValues: {
      logo: '',
    },
  });

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      const file = files[0];
      const result = imageValidation(file);
      if (result) {
        setOneBtnModal(t('message.alert'), result);
        return;
      }

      const url = URL.createObjectURL(file);
      setPreview(url);

      const formData = await saveFile(e, 'single');
      fileMutate(formData, {
        onSuccess(data, variables, context) {
          setValue('logo', data.payload.url);
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
          setPreview(null);
        },
      });
    }
  };

  const onSubmit = (data: z.infer<typeof settingSchema>) => {
    setRestaurant(
      {
        method: 'PUT',
        url: `/admin/restaurants/${restaurantId}`,
        data: {
          name: data.name,
          phone: data.phone,
          managerPhone: data.managerPhone,
          address: data.address || undefined,
          addressUrl: data.addressUrl || undefined,
          businessHours: data.businessHours || undefined,
          currencyUnit: data.currencyUnit || undefined,
          originMarking: data.originMarking || undefined,
          snsUrl: data.snsUrl || undefined,
          logo: data.logo || undefined,
        },
      },
      {
        onSuccess(data, variables, context) {
          setOneBtnModal(t('message.alert'), t('settings.레스토랑정보를수정하였습니다'));
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      const {
        managerPhone,
        name,
        phone,
        logo,
        currencyUnit,
        addressUrl,
        address,
        originMarking,
        snsUrl,
        businessHours,
      } = data.payload;
      reset({
        name,
        phone,
        managerPhone,
        logo,
        currencyUnit,
        addressUrl,
        address,
        originMarking,
        snsUrl,
        businessHours,
      });
    }
  }, [data]);

  const formData = watch();
  const logo = preview || formData.logo || '';
  const navigate = useNavigate();

  const { mutate: withdrawalMutate, isPending: deletePending } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const handleWithdrawal = () => {
    withdrawalMutate(
      {
        method: 'DELETE',
        url: `/admin/auth/withdrawal`,
      },
      {
        onSuccess(data, variables, context) {
          const logout = () => {
            removeCookie('restaurantId');
            removeCookie('accessToken');
            removeCookie('refreshToken');
            setTimeout(() => {
              navigate('/admin/login');
            }, 100);
          };
          setOneBtnModal(t('message.alert'), t('settings.회원을탈퇴하였습니다'), logout);
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
        onSettled(data, variables, context) {
          setCloseTwoBtnModal();
        },
      }
    );
  };

  return (
    <div className='flex w-full'>
      <section className='flex-[2] md:px-5'>
        <h2 className='text-xl font-bold mb-[32px] md:mb-[48px]'>{t('settings.설정')}</h2>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4 w-full'>
          <label
            className='bg-gray1 rounded-xl h-[100px] w-[150px] flex justify-center items-center cursor-pointer bg-cover bg-center'
            style={{
              backgroundImage: `url(${logo})`,
            }}
          >
            {!logo ? (
              <span className='text-gray2 text-[12px] md:text-[14px]'>{t('settings.레스토랑로고')}</span>
            ) : (
              <></>
            )}
            <input type='file' accept='images/*' hidden onChange={onChange} />
          </label>

          <Input
            {...register('name')}
            error={errors.name?.message}
            placeholder={t('settings.레스토랑이름')}
            required
            type='text'
            style='w-full'
          />
          <Input
            {...register('phone')}
            error={errors.phone?.message}
            placeholder={t('settings.레스토랑연락처')}
            required
            type='text'
            style='w-full'
          />
          <Input
            {...register('managerPhone')}
            error={errors.managerPhone?.message}
            placeholder={t('settings.담당자연락처')}
            required
            type='text'
            style='w-full'
          />
          <Input
            {...register('currencyUnit')}
            error={errors.currencyUnit?.message}
            placeholder={t('settings.결제통화단위')}
            type='text'
            style='w-full'
          />
          <Input
            {...register('addressUrl')}
            error={errors.addressUrl?.message}
            placeholder={t('settings.구글맵위치Url')}
            type='text'
            style='w-full'
          />
          <Input
            {...register('address')}
            error={errors.address?.message}
            placeholder={t('settings.주소')}
            type='text'
            style='w-full'
          />
          <Textarea
            {...register('originMarking')}
            error={errors.originMarking?.message}
            placeholder={t('settings.원산지표기')}
            style='h-[150px]'
          />
          <Input
            {...register('snsUrl')}
            error={errors.snsUrl?.message}
            placeholder={t('settings.인스타그램Url')}
            type='text'
            style='w-full'
          />
          <Textarea
            {...register('businessHours')}
            error={errors.businessHours?.message}
            placeholder={t('settings.오픈시간')}
            style='h-[80px]'
          />
          <Button
            type='submit'
            text={isPending ? t('message.processing') : t('settings.저장하기')}
            style={`bg-black text-white mt-[36px] ${isPending && 'bg-gray2'}`}
          />
          <Button
            type='button'
            onClick={() =>
              setTwoBtnModal({
                title: t('message.alert'),
                contents: t('settings.정말로탈퇴하시겠습니까'),
                leftText: t('settings.탈퇴'),
                rightText: t('settings.취소'),
                leftFn: handleWithdrawal,
                rightFn: setCloseTwoBtnModal,
              })
            }
            text={deletePending ? t('message.processing') : t('settings.회원탈퇴')}
            style={`bg-red-100 text-white ${deletePending && 'bg-gray2'}`}
          />
        </form>
      </section>
      {/* preview */}
      <SettingsPreview
        data={{
          name: formData.name,
          phone: formData.phone,
          managerPhone: formData.managerPhone,
          address: formData.address,
          addressUrl: formData.addressUrl,
          originMarking: formData.originMarking,
          snsUrl: formData.snsUrl,
          businessHours: formData.businessHours,
        }}
      />
    </div>
  );
}
