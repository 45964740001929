import { getCookie } from 'api/cookie';
import { jwtDecode } from 'jwt-decode';

interface UserInfo {
  id: string;
  language: 'ko' | 'en';
  email: string;
  role: 'member' | 'admin';
  iat: number;
  exp: number;
}

export function getUserInfo(): UserInfo | null {
  const accessToken = getCookie('accessToken');

  if (!accessToken) {
    return null;
  }

  const decodedToken: UserInfo = jwtDecode(accessToken);

  return decodedToken;
}
