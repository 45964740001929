import { api } from 'api';

export interface Restaurants {
  payload: [
    {
      pk: string;
      sk: string;
      id: string;
      name: string;
      phone: string;
      managerPhone: string;
    }
  ];
  error: null;
}

/** 레스토랑 리스트 조회 */
export const fetchRestaurants = () => {
  return api({
    method: 'GET',
    url: '/admin/restaurants',
  });
};

export interface Restaurant {
  payload: {
    id: string;
    name: string;
    phone: string;
    managerPhone: string;
    memberId: string;
    logo: string | undefined;
    currencyUnit: string | undefined;
    addressUrl: string | undefined;
    address: string | undefined;
    originMarking: string | undefined;
    snsUrl: string | undefined;
    businessHours: string | undefined;
  };
  error: null;
}

/** 레스토랑 조회 */
export const fetchRestaurant = (id: string) => {
  return api({
    method: 'GET',
    url: `/admin/restaurants/${id}`,
  });
};
