import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { PASSWORD_REGEX } from 'lib/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { setCookie } from 'api/cookie';
import Loader from 'components/common/Loader';
import { errorHandler } from 'lib/error';
import useModalStore from 'store/useModalStore';
import { useTranslation } from 'react-i18next';

type Props = {};

interface SignInResponse {
  payload: {
    accessToken: string;
    refreshToken: string;
  };
  error: null;
}

const createLoginSchema = (t: (key: string) => string) =>
  z.object({
    email: z.string().email({ message: t('validation.emailRequired') }),
    password: z.string().trim().regex(PASSWORD_REGEX, t('validation.passwordRegex')),
  });

export default function Login({}: Props) {
  const { setOneBtnModal } = useModalStore();
  const { t } = useTranslation();
  const loginSchema = createLoginSchema(t);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  });
  const { mutate, isPending } = useMutation<SignInResponse, ApiError, ApiProps>({ mutationFn: api });

  const onSubmit = (data: z.infer<typeof loginSchema>) => {
    const { email, password } = data;
    mutate(
      {
        method: 'POST',
        url: '/admin/auth/sign-in',
        data: {
          email,
          password,
        },
      },
      {
        async onSuccess(data, variables, context) {
          await setCookie('accessToken', data.payload.accessToken);
          await setCookie('refreshToken', data.payload.refreshToken);
          window.location.replace('/admin');
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  return (
    <div className='flex flex-col justify-center items-center w-full h-full max-w-[335px] m-auto'>
      {isPending && <Loader />}
      <LogoSvg width={88} height={18} className='mb-[50px] hidden md:block ' />
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[15px] w-full'>
        <AuthInput
          {...register('email')}
          error={errors.email?.message}
          placeholder={t('signIn.이메일')}
          required={true}
          type='text'
        />
        <AuthInput
          {...register('password')}
          error={errors.password?.message}
          placeholder={t('signIn.비밀번호')}
          required={true}
          type='password'
        />

        <AuthButton style='mt-[31px] bg-black text-white' text={t('signIn.로그인')} type='submit' />
      </form>
      <div className='w-full flex justify-between item-center gap-[30px] mt-[29px]'>
        <Link to={'/admin/signUp'} className='w-full'>
          <AuthButton
            style={`${isPending ? 'bg-gray1 text-white' : 'bg-lightGreen1 text-gray4'}`}
            text={isPending ? t('signIn.회원가입중') : t('signIn.회원가입')}
            type='button'
            disabled={isPending}
          />
        </Link>
        <Link to={'/admin/find/password'} className='w-full'>
          <AuthButton
            style={`${isPending ? 'bg-gray1 text-white' : 'bg-gray4 text-white'}`}
            text={isPending ? t('signIn.비밀번호재설정중') : t('signIn.비밀번호재설정')}
            type='button'
            disabled={isPending}
          />
        </Link>
      </div>
    </div>
  );
}
