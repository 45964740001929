export default function CategorySkeleton() {
  return (
    <div className='mt-[18px]'>
      <div className='scroll w-full px-5 flex items-center overflow-x-scroll pb-[18px]'>
        {[1, 2, 3, 4, 5].map((item) => (
          <div key={item} className='relative pr-5'>
            <div className='absolute top-[2px] h-[1px] bg-gray1 w-full' />
            <span className='flex flex-col items-center justify-center gap-[18px]'>
              <div className='w-[6px] h-[6px] rounded-[2px] bg-none strong-pulse' />
              <div className='w-[60px] h-[16px] rounded-[4px] bg-gray1 strong-pulse' />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
