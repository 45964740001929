// ... existing imports ...

import { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as ChevronDown } from 'assets/svg/arrow-y.svg';

type ListType = { value: string; label: string; onClick?: () => void };

type Props = {
  list: ListType[];
  defaultValue?: string;
  onChange?: (value: string, label?: string) => void;
  clicked?: boolean;
  placeholder?: string;
  style?: string;
  arrowWidth?: number;
  arrowHeight?: number;
};

export default function Select({
  list,
  defaultValue,
  onChange,
  clicked,
  placeholder = '선택하세요',
  style,
  arrowWidth,
  arrowHeight,
}: Props) {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState<string>(list[0]?.label);
  const containerRef = useRef<HTMLDivElement>(null);

  // 선택 핸들러
  const handleSelect = useCallback(
    (item: ListType) => {
      setSelected(item.value);
      setShowList(false);
      onChange?.(item.value, item.label);
    },
    [onChange]
  );

  // 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className='relative'>
      <button
        type='button'
        aria-haspopup='listbox'
        aria-expanded={showList}
        className={`flex items-center gap-2 w-full text-left rounded-md text-[10px] md:text-[14px] ${style} `}
        onClick={() => setShowList((prev) => !prev)}
      >
        {defaultValue ? defaultValue : list.find((item) => item.value === selected)?.label || placeholder}

        <ChevronDown
          stroke={clicked ? '#20D48C' : '#989898'}
          className={`transition-transform ${showList ? 'rotate-180' : ''}`}
          width={arrowWidth ? arrowWidth : 12}
          height={arrowHeight ? arrowHeight : 8}
        />
      </button>

      {showList && (
        <ul
          role='listbox'
          className='absolute overflow-y-auto  max-h-[500px] min-w-[80px] mt-1 border border-black rounded-md shadow-lg bg-white z-10 overflow-hidden'
        >
          {list.length > 0 &&
            list.map((item) => (
              <li
                key={item.value}
                role='option'
                aria-selected={selected === item.value}
                className={`px-4 pt-2 cursor-pointer hover:bg-gray-100 font-bold text-[14px] text-nowrap
                ${selected === item.value ? 'bg-gray-50' : ''}`}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  }
                  handleSelect(item);
                }}
              >
                {item.label}
                <div className='border-b border-gray1 pb-2' />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
