import { api } from 'api';

/* 카테고리 생성 */
export const createCategoryApi = (menuId: string, name: string) => {
  if (!menuId) {
    return;
  }
  return api({
    method: 'POST',
    url: `/admin/menus/${menuId}/categories`,
    data: {
      name,
    },
  });
};

/* 카테고리 수정 */
export const updateCategoryApi = (categoryId: string, name: string) => {
  if (!categoryId) {
    return;
  }
  return api({
    method: 'PUT',
    url: `/admin/categories/${categoryId}`,
    data: {
      name,
    },
  });
};

export interface CategoriesResponse {
  payload: Category[];
  error: null;
}

export interface Category {
  id: string;
  name: string;
  menuId: string;
  order: number;
  items: CategoryItems[];
}

export interface CategoryItems {
  id: string;
  name: string;
  image: string;
  description: string;
  categoryId: string;
  menuId: string;
  options: {
    name?: string;
    price: number;
  }[];
  tags: string[];
  order: number;
}

/* 카테고리 목록 조회 */
export const getCategories = (menuId: string) => {
  if (!menuId) {
    return;
  }
  return api({
    method: 'GET',
    url: `/admin/menus/${menuId}/categories`,
  });
};
