import { api } from 'api';

export interface BannersResponse {
  payload: BannerPayload[];
  error: null;
}

export interface BannerPayload {
  id: string;
  image: string;
  order: number;
  link?: string;
  isActivated: boolean;
  restaurantId: string;
}

/* 레스토랑 리스트 조회 */
export const fetchBanners = (restaurantId: string) => {
  if (!restaurantId) {
    return;
  }
  return api({
    method: 'GET',
    url: `/admin/restaurants/${restaurantId}/banners`,
  });
};

export interface BannerDetailResponse {
  payload: {
    id: string;
    image: string;
    link: string;
    order: number;
    isActivated: boolean;
    restaurantId: string;
  };
  error: null;
}

/* 배너 상세 조회 */
export const fetchBannerDetail = (bannerId: string) => {
  if (!bannerId) {
    return;
  }
  return api({
    method: 'GET',
    url: `/admin/banners/${bannerId}`,
  });
};
