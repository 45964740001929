import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from './lang/en.json';
import * as ko from './lang/ko.json';
import { getCookie } from 'api/cookie';

const resources = {
  'en-US': {
    translation: en,
  },
  'ko-KR': {
    translation: ko,
  },
};

const language = getCookie('language') || 'ko-KR';

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  // lng: 'en-US',
  fallbackLng: 'ko-KR',
  // fallbackLng: 'en-US',
  // keySeparator를 true로 설정하거나 제거
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});
export default i18n;
