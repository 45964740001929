import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ReactComponent as LanguageSvg } from 'assets/svg/global.svg';

export default function MenuListSkeleton() {
  return (
    <div className='h-[100dvh] relative pt-[56px] flex flex-col items-center px-[40px]'>
      <span className='absolute top-[22px] right-[26px]'>
        <LanguageSvg width={28} hanging={28} />
      </span>
      <span>
        <LogoSvg width={158} hanging={56} />
      </span>

      {/* 레스토랑 이름 스켈레톤 */}
      <div className='h-[24px] w-[200px] mt-[42px] bg-gray1 rounded strong-pulse' />

      {/* 메뉴 버튼 스켈레톤 */}
      <div className='w-full h-full flex flex-col items-center justify-center gap-[16px]'>
        {[1, 2, 3].map((_, index) => (
          <div key={index} className='w-full h-[50px] bg-gray1 rounded-[10px] strong-pulse' />
        ))}
        {/* 돌아가기 버튼 스켈레톤 */}
        <div className='w-full h-[50px] bg-gray1 rounded-[10px] strong-pulse' />
      </div>
    </div>
  );
}
