import React from 'react';

export default function ItemListSkeleton() {
  return (
    <div className='px-5'>
      <div className='h-6 w-24 bg-gray1 strong-pulse rounded-md mt-[13px] mb-[10px]' />
      {[1, 2, 3, 5, 6, 7].map((item) => (
        <div key={item} className='flex items-center mb-5'>
          <div className='bg-gray1 strong-pulse overflow-hidden rounded-[15px] aspect-[100/107] min-w-[100px] max-w-[100px] w-full mr-[53px]' />
          <div className='flex-1'>
            <div className='h-5 w-32 bg-gray1 strong-pulse rounded-md mb-3' />
            <div className='h-5 w-24 bg-gray1 strong-pulse rounded-md mb-3' />
            <div className='h-4 bg-gray1 strong-pulse rounded-md w-full mb-1' />
            <div className='h-4 bg-gray1 strong-pulse rounded-md w-2/3' />
          </div>
        </div>
      ))}
    </div>
  );
}
