import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';

export default function SkeletonPreview() {
  return (
    <section className='pl-8 pt-4 border-l border-gray1 hidden xl:block'>
      <h2 className='text-gray-600 text-[18px] font-bold'>미리보기</h2>
      <div className='mt-6 relative'>
        <div className='absolute z-[-1]'>
          <PhonBackground />
        </div>
        <div className='pt-[63px] w-[375px] h-[667px] *:text-[12px] flex flex-col pl-[48px] pr-[56px]'>
          <div className='py'>
            <div className='animation-pulse mt-[13px] aspect-[375/130] bg-gray1 rounded-[15px] bg-cover bg-center' />;
            {[1, 2, 3, 4, 5].map((_, index) => (
              <div key={index} className='flex flex-col gap-2 py-4 border-b border-gray1 last:border-b-0'>
                <div className='flex gap-3'>
                  <div className='animation-pulse w-[60px] h-[60px] rounded-[2px] bg-gray1' />
                  <div className='flex flex-col gap-2 flex-1'>
                    <div className='animation-pulse w-[120px] h-[18px] rounded-[2px] bg-gray1' />
                    <div className='animation-pulse w-full h-[14px] rounded-[2px] bg-gray1/60' />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
