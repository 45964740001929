import { api } from 'api';
import { getCookie } from 'api/cookie';
import { redirect } from 'react-router';

export interface MenuResponse {
  payload: {
    id: string;
    name: string;
    language: string;
    restaurantId: string;
    order: number;
  }[];

  error: null;
}

/** 메뉴판 목록 조회 */
export const fetchMenus = () => {
  const restaurantId = getCookie('restaurantId');
  if (!restaurantId) {
    redirect('/404');
  }
  return api({
    method: 'GET',
    url: `/admin/restaurants/${restaurantId}/menus`,
  });
};

// export interface MenuDetailResponse {
//   payload: MenuDetail;
//   error: null;
// }

/* 메뉴 상세 조회 */
export const getMenuDetail = (menuId: string) => {
  return api({
    method: 'GET',
    url: `/admin/menus/${menuId}`,
  });
};
