import { Link, useParams } from 'react-router';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';
import { useTranslation } from 'react-i18next';
import useRestaurantInfoStore from 'store/service/useRestaurantInfo';
type Props = {};

export const settingsItemKeys = [
  { labelKey: 'settings.레스토랑로고', value: 'logo' },
  { labelKey: 'settings.레스토랑이름', value: 'name' },
  { labelKey: 'settings.레스토랑연락처', value: 'phone' },
  { labelKey: 'settings.담당자연락처', value: 'managerPhone' },
  { labelKey: 'settings.결제통화단위', value: 'currencyUnit' },
  { labelKey: 'settings.구글맵위치Url', value: 'addressUrl' },
  { labelKey: 'settings.주소', value: 'address' },
  { labelKey: 'settings.원산지표기', value: 'originMarking' },
  { labelKey: 'settings.인스타그램Url', value: 'snsUrl' },
  { labelKey: 'settings.오픈시간', value: 'businessHours' },
];

export default function RestaurantMenuInfo({}: Props) {
  const { t } = useTranslation();
  const { restaurantId } = useParams();

  const { restaurantInfo } = useRestaurantInfoStore();

  const settingsItems = settingsItemKeys.map((item) => ({
    label: t(item.labelKey),
    value: item.value,
  }));

  return (
    <div className='p-4 font-NEXONLv1GothicOTF relative'>
      <div className='flex items-center justify-between border-b border-b-gray1 pb-4'>
        <Link to={`/restaurant/${restaurantId}/menus`} className='size-6'>
          <BackIcon fill='#9C9C9C' width={20} height={20} />
        </Link>
        <h1 className='text-[18px] font-bold text-center'>{t('settings.레스토랑정보')}</h1>
        <span className='size-6' />
      </div>
      <ul className='flex flex-col gap-4 mt-8'>
        {settingsItems.map((item, index) => (
          <li key={index} className='flex items-center justify-between gap-3'>
            <label className='w-1/2 text-[12px] font-semibold whitespace-pre'>{item.label}</label>
            <span className='w-1/2 text-[12px] text-end break-all flex items-center justify-end gap-2'>
              {item.value === 'logo' ? (
                <img src={restaurantInfo.logo} alt={restaurantInfo.name} className='w-[120px] h-[120px] rounded-3xl' />
              ) : (
                restaurantInfo?.[item.value as keyof typeof restaurantInfo] || '-'
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
