import { useQuery } from '@tanstack/react-query';
import { ApiError } from 'api';
import { fetchMenus, MenuResponse } from 'api/admin/menu';
import Loader from 'components/common/Loader';
import MenuList from 'components/menu/MenuList';
import Unlisted from 'components/menu/Unlisted';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type Props = {};

/**
 * 메뉴판명
 * 수동 작성 (언어선택)
 * 엑셀 업로드
 */

export default function Menu({}: Props) {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery<MenuResponse, ApiError>({
    queryKey: ['fetchMenus'],
    queryFn: fetchMenus,
  });

  useEffect(() => {
    if (!isLoading && data?.payload.length === 0) navigate('/admin/menu/create');
  }, [data, isLoading]);

  if (isLoading) return <Loader />;

  return <div className='flex w-full'>{data?.payload.length > 0 ? <MenuList /> : <Unlisted />}</div>;
}
