import { api } from 'api';

export interface PartnersResponse {
  payload: {
    id: string;
    email: string;
    role: 'admin' | 'member';
    language: 'ko' | 'en' | string;
    isApproved: boolean;
    restaurants: {
      name: string;
      phone: string;
      managerPhone: string;
    }[];
  }[];

  error: null;
}

export const fetchPartners = async () => {
  return await api({
    method: 'GET',
    url: '/admin/members',
  });
};
