import useBodyOverflow from 'hooks/useBodyOverflow';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useModalStore from 'store/useModalStore';

type Props = {
  contents: string;
  title: string;
  onClick?: () => void;
};

export default function OneBtnModal({ contents, title, onClick }: Props) {
  useBodyOverflow();
  const bgRef = useRef<HTMLDivElement>(null);
  const { setCloseOneBtnModal } = useModalStore();
  const { t } = useTranslation();
  const handleClick = () => setCloseOneBtnModal();

  return (
    <div className='modal-bg z-[9999999]' ref={bgRef}>
      <div
        className='flex flex-col justify-between items-center shadow-xl w-[322px] h-[260px] rounded-[25px] bg-white overflow-hidden'
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className='flex items-center justify-center h-[55px] text-[16px] w-full text-gray2 border-b border-gray1'>
          {title}
        </h2>
        <span className='text-[14px] text-center leading-8 whitespace-pre'>{contents}</span>
        <div className='flex justify-between items-center w-full h-[57px]'>
          <button
            className='w-full h-full bg-main3 text-white font-bold'
            onClick={() => {
              if (onClick) {
                onClick();
              }

              handleClick();
            }}
          >
            {t('message.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
}
