import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import MoreSelect from 'components/common/MoreSlect';
import { useNavigate, useParams } from 'react-router';
import DraggableItems from 'components/Drag/DraggableItems';
import CreateCategoryModal from 'components/menu/CreateCategoryModal';
import useModalStore from 'store/useModalStore';
import CreateMenuModal from 'components/menu/CreateMenuModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchMenus, MenuResponse } from 'api/admin/menu';
import { CategoriesResponse, Category, getCategories } from 'api/admin/cateogry';
import { api, ApiError, ApiProps } from 'api';
import { useEffect, useState } from 'react';
import { errorHandler } from 'lib/error';
import MenuDetailPreview from 'components/preview/menuDetail';
import SkeletonPreview from 'components/service/SkeletonPreview';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { useTranslation } from 'react-i18next';
import { GetRestaurant, getRestaurant } from 'api/service/restaurant';

type Props = {};

export default function MenuDetail({}: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { modals, createMenuModal, setModals, setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();
  // const menuList = queryClient.getQueryData<MenuResponse>(['fetchMenus']);

  const { data: menuList, isLoading: menuListLoading } = useQuery<MenuResponse, ApiError>({
    queryKey: ['fetchMenus'],
    queryFn: fetchMenus,
  });

  const menu = menuList?.payload.find((item) => item.id === params.id);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);

  const { data: categories } = useQuery<CategoriesResponse>({
    queryKey: ['categories', params.id],
    queryFn: () => getCategories(params.id),
  });
  const { data: restaurant } = useQuery<GetRestaurant>({
    queryKey: ['restaurant', menu?.restaurantId],
    queryFn: () => getRestaurant(menu?.restaurantId),
    enabled: !!menu?.restaurantId,
  });

  const { mutate: deleteMenuMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const deleteMenu = () => {
    if (!menu?.id) return;

    deleteMenuMutate(
      {
        method: 'DELETE',
        url: `/admin/menus/${menu?.id}`,
      },
      {
        onSuccess: () => {
          setOneBtnModal(t('message.alert'), t('menu.메뉴판을삭제하였습니다'), () => {
            queryClient.invalidateQueries({ queryKey: ['fetchMenus'] });
            queryClient.invalidateQueries({ queryKey: ['categories', params?.id] });
            navigate('/admin/menu');
          });
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
        onSettled(data, error, variables, context) {
          setCloseTwoBtnModal();
        },
      }
    );
  };

  const { mutate: categoryReorderMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const { currentItems, handleDragStart, handleDragOver, handleDrop, setItems } = useDragAndDrop({
    items: [],
    onReorder: (startId, endId) => {
      categoryReorderMutate({
        method: 'PATCH',
        url: `/admin/categories/order`,
        data: {
          categoryIds: [startId, endId],
        },
      });
    },
  });

  useEffect(() => {
    if (categories?.payload) {
      setItems(categories.payload);
    }
  }, [categories]);

  return (
    <>
      {createMenuModal.isOpen && <CreateMenuModal />}
      {modals.createCategoryModal.isOpen && (
        <CreateCategoryModal selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      )}

      {/* contents */}
      <div className='flex-[2] md:pt-[40px] md:px-[45px]'>
        <div className='flex items-center justify-between pb-[48px]'>
          <h2 className='text-xl font-bold'>{t('menu.메뉴판')}</h2>
          <button
            onClick={() => setModals('createCategoryModal')}
            className='text-[12px] md:text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main1 hover:transition-colors duration-300 hover:strong-pulse'
          >
            {t('menu.새카테고리만들기')}
          </button>
        </div>
        <section className='flex flex-col '>
          <ul className='flex flex-col gap-2'>
            <li className='flex justify-between items-center'>
              <span className='text-[12px] md:text-[14px] font-semibold'>{`${t(`language.${menu?.language}`)} ${t(
                'menu.메뉴판'
              )}`}</span>
              <span className='flex justify-start items-center gap-4'>
                <GlobalIcon width={20} height={20} />
                <span className='text-[12px] md:text-[14px]'>{t(`language.${menu?.language}`)}</span>
                <span className='cursor-pointer'>
                  <MoreSelect
                    list={[
                      {
                        label: t('menu.삭제하기'),
                        name: t('menu.삭제하기'),
                        onClick: () => {
                          setTwoBtnModal({
                            title: t('message.alert'),
                            contents: t('menu.정말로메뉴판을삭제하시겠습니까'),
                            leftText: t('menu.취소'),
                            rightText: t('menu.삭제'),
                            leftFn: setCloseTwoBtnModal,
                            rightFn: deleteMenu,
                          });
                        },
                      },
                    ]}
                  />
                </span>
              </span>
            </li>
          </ul>

          {/* menu List */}
          {currentItems && currentItems.length > 0 ? (
            <ul className='flex flex-col gap-6 mt-5'>
              {currentItems.map((item, index) => (
                <li
                  key={item.id}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDrop={() => handleDrop(index)}
                  className='cursor-grab'
                >
                  <DraggableItems setSelectedCategory={setSelectedCategory} category={item} />
                </li>
              ))}
            </ul>
          ) : (
            ''
          )}
        </section>
      </div>

      {/* preview */}
      {!currentItems ? (
        <SkeletonPreview />
      ) : (
        <MenuDetailPreview
          data={{
            restaurantName: restaurant?.payload?.name,
            menuName: menu?.name,
            categories: currentItems,
            language: menu?.language,
          }}
        />
      )}
    </>
  );
}
