import { useCallback, useState } from 'react';

interface UseDragAndDropProps<T> {
  items: T[];
  onReorder?: (startId: string, endId: string) => void;
}

export function useDragAndDrop<T>({ items, onReorder }: UseDragAndDropProps<T>) {
  const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
  const [currentItems, setCurrentItems] = useState<T[]>(items);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);

  const setItems = useCallback((newItems: any[]) => {
    setCurrentItems(newItems);
  }, []);

  const handleDragStart = (index: number) => {
    setDragStartIndex(index);
  };

  const handleDragOver = (e: React.DragEvent, index: number) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDrop = async (dropIndex: number) => {
    if (dragStartIndex === null) return;
    setDragOverIndex(null);
    const newItems: any = [...currentItems];

    /* API 호출 */
    await onReorder?.(newItems[dragStartIndex].id, newItems[dropIndex].id);

    /* 데이터 업데이트 */
    [newItems[dragStartIndex], newItems[dropIndex]] = [newItems[dropIndex], newItems[dragStartIndex]];
    setCurrentItems(newItems);
    setDragStartIndex(null);
  };

  return {
    currentItems,
    setItems,
    handleDragStart,
    handleDragOver,
    dragStartIndex,
    dragOverIndex,
    handleDrop,
  };
}
