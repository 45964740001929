import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';

import { BrowserRouter, Route, Routes } from 'react-router';
import AdminBeforeLoginLayout from 'components/common/AdminBeforeLoginLayout';
import AdminAfterLoginLayout from 'components/common/AdminAfterLoginLayout';
import Home from 'pages/admin/Home';
import Dashboard from './pages/admin/Dashboard';
import SignUp from './pages/admin/SignUp';
import Login from './pages/admin/Login';
import FindPassword from './pages/admin/FindPassword';
import Menu from 'pages/admin/Menu';
import MenuDetail from 'pages/admin/MenuDetail';
import MenuCreate from 'pages/admin/MenuCreate';
import Banners from 'pages/admin/Banners';
import Partners from 'pages/admin/Partners';
import RestaurantMenuBoard from 'pages/service/RestaurantMenuBoard';
import RestaurantMenuList from 'pages/service/RestaurantMenuList';
import RestaurantMenuDetail from 'pages/service/RestaurantMenuDetail';
import RestaurantLayout from 'components/service/RestaurantLayout';
import MenuLayout from 'components/service/MenuLayout';
import Landing from 'pages/landing/Landing';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import Loader from 'components/common/Loader';
import RestaurantMenuInfo from 'pages/service/RestaurantMenuInfo';
import Settings from 'pages/admin/Settings';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 0, // 데이터를 가져오자마자 오래된 값으로 판단합니다.
      gcTime: 120000, // 쿼리가 비활성화 되면 2분 후 캐시가 삭제됩니다.
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            {/* 관리자 */}
            <Route element={<AdminBeforeLoginLayout />}>
              <Route path='/admin/signUp' element={<SignUp />} />
              <Route path='/admin/login' element={<Login />} />
              <Route path='/admin/find/password' element={<FindPassword />} />
            </Route>
            <Route element={<AdminAfterLoginLayout />}>
              <Route path='/admin' element={<Home />} />
              <Route path='/admin/dashboard/:id' element={<Dashboard />} />
              <Route path='/admin/menu' element={<Menu />} />
              <Route path='/admin/menu/create' element={<MenuCreate />} />
              <Route path='/admin/menu/:id' element={<MenuDetail />} />
              <Route path='/admin/settings' element={<Settings />} />
              <Route path='/admin/banners' element={<Banners />} />
              <Route path='/admin/partners' element={<Partners />} />
            </Route>

            {/* 서비스(메뉴판) */}

            <Route element={<RestaurantLayout />}>
              {/* <Route path='restaurant/:id' element={<RestaurantList />} /> */}
              <Route path='restaurant/:restaurantId/menus' element={<RestaurantMenuList />} />
              <Route path='restaurant/:restaurantId/info' element={<RestaurantMenuInfo />} />
            </Route>
            <Route element={<MenuLayout />}>
              <Route path='restaurant/:restaurantId/menus/:menuId' element={<RestaurantMenuBoard />} />
              <Route path='restaurant/:restaurantId/menus/:menuId/detail/:itemId' element={<RestaurantMenuDetail />} />
            </Route>

            {/* 랜딩 */}
            <Route path='/' element={<Landing />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
