import { AxiosError } from 'axios';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { ApiError, multerApi, MulterApiPros } from 'api';
import { IMAGE_SIZE_OVER_MESSAGE, ONLY_IMAGE_FILE_MESSAGE } from 'lib/constants';

export interface ImageData {
  fileName: string;
  fileSize: number;
  url: string;
}

export interface ImgResponse {
  error: any;
  payload: ImageData;
}

export default function useFile() {
  // 이미지 업로드
  const { mutate: fileMutate } = useMutation<ImgResponse, ApiError, MulterApiPros>({
    mutationFn: (param) => {
      return multerApi({
        formData: param.formData,
      });
    },
  });

  // 이미지 검증 함수
  const imageValidation = (file: File) => {
    let result = '';
    if (!file.type.startsWith('image/')) {
      result = ONLY_IMAGE_FILE_MESSAGE;
    }
    const fileSizeInMd = file.size / (1024 * 1024);
    if (fileSizeInMd > 2) {
      result = IMAGE_SIZE_OVER_MESSAGE;
    }
    return result;
  };

  // FormData 생성 함수
  const createFormData = (file: File) => {
    const formData = new FormData();
    formData.append('file', file, encodeURIComponent(file.name));
    return formData;
  };

  // 이미지 저장 함수 (단일, 다중 이미지 모두 처리)
  const saveFile = (e: React.ChangeEvent<HTMLInputElement>, type: 'single' | 'multiple') => {
    const { files } = e.target;
    if (files) {
      let formData: FormData;
      if (type === 'single') {
        formData = createFormData(files[0]);
      } else {
        formData = new FormData();
        Array.from(files).forEach((file) => {
          formData.append('file', file, encodeURIComponent(file.name));
        });
      }
      return {
        name: files[0].name,
        size: files[0].size,
        formData: formData,
      };
    }
  };
  return { fileMutate, saveFile, imageValidation };
}
