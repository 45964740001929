import { api } from 'api';

export interface GetRestaurant {
  payload: GetRestaurantInfo;
  error: null;
}

export interface GetRestaurantInfo {
  id: string;
  name: string;
  phone: string;
  managerPhone: string;
  logo: string | null;
  currencyUnit: string | null;
  addressUrl: string | null;
  address: string | null;
  originMarking: string | null;
  snsUrl: string | null;
  businessHours: string | null;
  memberId: string;
}

export const getRestaurant = async (restaurantId: string) => {
  if (!restaurantId) {
    return;
  }
  return await api({
    method: 'GET',
    url: `/service/restaurants/${restaurantId}`,
  });
};
