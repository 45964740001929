import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { Link, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { GetRestaurantMenuList, getRestaurantMenuList, RestaurantMenu } from 'api/service/menu';
import MenuListSkeleton from 'components/service/skeleton/menuList';
import { getRestaurant, GetRestaurant } from 'api/service/restaurant';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { getLanguageDisplay } from 'lib/language';
import { setCookie } from 'api/cookie';
import { useTranslation } from 'react-i18next';

export default function RestaurantMenuList() {
  const { restaurantId } = useParams();
  const { i18n } = useTranslation();
  const { data: restaurant, isLoading: restaurantLoading } = useQuery<GetRestaurant>({
    queryKey: ['service/restaurant', restaurantId],
    queryFn: () => getRestaurant(restaurantId),
    enabled: !!restaurantId,
  });

  const { data: menuList, isLoading: menuListLoading } = useQuery<GetRestaurantMenuList>({
    queryKey: ['service/restaurant/menus', restaurantId],
    queryFn: () => getRestaurantMenuList(restaurantId),
    enabled: !!restaurantId,
  });

  const handleMenu = (menu: RestaurantMenu) => {
    const newLanguage = menu.language === 'ko' ? 'ko-KR' : 'en-US';
    setCookie('language', newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  if (restaurantLoading || menuListLoading) {
    return <MenuListSkeleton />;
  }

  return (
    <div className='relative pt-[56px] flex flex-col items-center px-[40px]'>
      <Link
        to={`/restaurant/${restaurantId}/info`}
        className='absolute top-[16px] right-[22px] flex items-center gap-2'
      >
        <InformationCircleIcon width={34} height={34} fill='#2ECC71' />
      </Link>
      <span>
        <LogoSvg width={158} hanging={56} />
      </span>
      <h2 className='text-[15px] mt-[42px] font-bold'>{restaurant?.payload.name}</h2>

      <div className='w-full h-full mt-[50px] flex flex-col items-center justify-center gap-[16px] '>
        {menuList?.payload.map((menu, index) => (
          <Link
            to={`/restaurant/${restaurantId}/menus/${menu.id}`}
            key={index}
            className='w-full rounded-[10px] bg-main7 py-[15px] text-center font-bold text-[15px] text-white'
            onClick={() => handleMenu(menu)}
          >
            {menu.language === 'ko' ? menu.name : getLanguageDisplay(menu.language)}
          </Link>
        ))}
        {/* <Link
          to={`/restaurant`}
          className='w-full rounded-[10px] bg-gray1 py-[15px] text-center font-bold text-[15px] text-gray2'
        >
          돌아가기
        </Link> */}
      </div>
    </div>
  );
}
