import { CategoryItems } from 'api/admin/cateogry';
import { create } from 'zustand';

interface ModalState {
  isOpen: boolean;
}

interface UseModalStore<T extends string> {
  modals: Record<T, ModalState>;
  oneBtnModal: {
    isOpen: boolean;
    title: string;
    alertMsg: string;
    onClick?: () => void;
  };
  twoBtnModal: {
    isOpen: boolean;
    title: string;
    contents: string;
    leftText: string;
    rightText: string;
    leftFn: () => void;
    rightFn: () => void;
  };
  createMenuModal: {
    isOpen: boolean;
    categoryName: string;
    menuId: string;
    categoryId: string;
    item?: CategoryItems;
  };
  setCreateMenuModal: (categoryId: string, categoryName: string, menuId: string, item?: CategoryItems) => void;
  setCloseCreateMenuModal: () => void;
  setModals: (modalName: T) => void;
  setOneBtnModal: (title: string, alertMsg: string, onClick?: () => void) => void;
  setCloseOneBtnModal: () => void;
  setTwoBtnModal: ({
    title,
    contents,
    leftText,
    rightText,
    leftFn,
    rightFn,
  }: {
    title: string;
    contents: string;
    leftText: string;
    rightText: string;
    leftFn: () => void;
    rightFn: () => void;
  }) => void;
  setCloseTwoBtnModal: () => void;
}

type ModalNames = 'createCategoryModal' | 'createTagModal' | 'bannerDetailModal' | 'menuSettingModal' | 'languageModal';

const useModalStore = create<UseModalStore<ModalNames>>((set) => ({
  modals: {
    createCategoryModal: { isOpen: false },
    createTagModal: { isOpen: false },
    bannerDetailModal: { isOpen: false },
    menuSettingModal: { isOpen: false },
    languageModal: { isOpen: false },
  },
  oneBtnModal: { isOpen: false, title: '', alertMsg: '', onClick: () => {} },
  twoBtnModal: {
    isOpen: false,
    title: '',
    contents: '',
    leftText: '',
    rightText: '',
    leftFn: () => {},
    rightFn: () => {},
  },
  createMenuModal: { isOpen: false, categoryName: '', menuId: '', categoryId: '', item: undefined },
  setCreateMenuModal: (categoryId: string, categoryName: string, menuId: string, item?: CategoryItems) =>
    set((state) => ({
      createMenuModal: { isOpen: true, categoryId, categoryName, menuId, item },
    })),
  setCloseCreateMenuModal: () =>
    set((state) => ({
      createMenuModal: { isOpen: false, categoryId: '', categoryName: '', menuId: '', item: undefined },
    })),
  setModals: (modalName) =>
    set((state) => ({
      modals: {
        ...state.modals,
        [modalName]: { isOpen: !state.modals[modalName].isOpen },
      },
    })),
  setOneBtnModal: (title, alertMsg, onClick) => {
    set((state) => {
      const updatedOneBtnModal = {
        isOpen: true,
        title: title ? title : state.oneBtnModal.title,
        alertMsg: alertMsg ? alertMsg : state.oneBtnModal.alertMsg,
        onClick: onClick ? onClick : state.oneBtnModal.onClick,
      };
      return { oneBtnModal: updatedOneBtnModal };
    });
  },
  setCloseOneBtnModal: () =>
    set((state) => ({
      oneBtnModal: {
        isOpen: false,
        title: '',
        alertMsg: '',
        onClick: () => {},
      },
    })),
  setTwoBtnModal: ({
    title,
    contents,
    leftText,
    rightText,
    leftFn,
    rightFn,
  }: {
    title: string;
    contents: string;
    leftText: string;
    rightText: string;
    leftFn: () => void;
    rightFn: () => void;
  }) =>
    set((state) => ({
      twoBtnModal: {
        isOpen: true,
        title: title ? title : state.twoBtnModal.title,
        contents: contents ? contents : state.twoBtnModal.contents,
        leftText: leftText ? leftText : state.twoBtnModal.leftText,
        rightText: rightText ? rightText : state.twoBtnModal.rightText,
        leftFn: leftFn ? leftFn : state.twoBtnModal.leftFn,
        rightFn: rightFn ? rightFn : state.twoBtnModal.rightFn,
      },
    })),
  setCloseTwoBtnModal: () =>
    set((state) => ({
      twoBtnModal: {
        isOpen: false,
        title: '',
        contents: '',
        leftText: '',
        rightText: '',
        leftFn: () => {},
        rightFn: () => {},
      },
    })),
}));

export default useModalStore;
