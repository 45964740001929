import MenuHeader from 'components/service/MenuHeader';
import { useEffect, useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/svg/check-icon.svg';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { GetItems, getServiceCategoryList, GetServiceCategoryList } from 'api/service/category';
import { getMaxPrice, getMinPrice, hasDifferentPrices } from 'lib/meun';
import ItemDetailSkeleton from 'components/service/skeleton/itemDetail';
import useRestaurantInfoStore from 'store/service/useRestaurantInfo';

type Props = {};

export default function RestaurantMenuDetail({}: Props) {
  const navigate = useNavigate();
  const { menuId, itemId } = useParams();
  const { restaurantInfo } = useRestaurantInfoStore();
  const [item, setItem] = useState<GetItems>();
  const { data: categoryList, isLoading: categoryListLoading } = useQuery<GetServiceCategoryList>({
    queryKey: ['service/category', menuId],
    queryFn: () => getServiceCategoryList(menuId),
    enabled: !!menuId,
  });

  useEffect(() => {
    if (categoryList) {
      const category = categoryList.payload.find((category) => category.items.some((item) => item.id === itemId));
      const foundItem = category?.items.find((item) => item.id === itemId);
      setItem(foundItem);
    }
  }, [categoryList, itemId]);

  if (!item) {
    <ItemDetailSkeleton />;
  }

  return (
    <div className='pb-[50px]'>
      <MenuHeader />
      <div className='relative bg-gray1 w-full aspect-[415/324]'>
        <span onClick={() => navigate(-1)} className='absolute top-[22.51px] left-[18.83px]'>
          <BackIcon fill='white' />
        </span>
        <img src={item?.image} alt={item?.name} className='w-full h-full object-cover' />
      </div>

      <div className='px-[30px]'>
        <div className='flex items-center gap-2 flex-wrap py-[30px] border-b border-gray1'>
          {item?.tags.map((tag, index) => (
            <span className='text-sm bg-gray1 rounded-[5.5px] px-2 py-1 font-bold font-NEXONLv1GothicOTF' key={index}>
              {`#${tag}`}
            </span>
          ))}
        </div>
      </div>

      <div className='px-6'>
        <div className='flex items-center gap-[10px] mt-6 '>
          <h2 className='font-bold text-[18px]'>{item?.name}</h2>

          <CheckIcon />
        </div>
        <span className='mt-3 text-main6 text-xs leading-[9.42px] font-bold bg-yellow2 rounded-[5.5px]'>
          {`${
            item?.options
              ? `${getMinPrice(item?.options).toLocaleString()} ${
                  restaurantInfo.currencyUnit ? restaurantInfo.currencyUnit : '원'
                }`
              : ''
          }`}
        </span>
        <p className='mt-8 text-xs leading-4 text-center'>{item?.description}</p>

        <ul className='flex flex-col gap-2 mt-12'>
          {item?.options
            .filter((option) => option.name)
            .map((option, index) => (
              <li className='text-xs leading-4 font-semibold' key={index}>
                {option.name} : {option.price} {restaurantInfo.currencyUnit ? restaurantInfo.currencyUnit : '원'}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
