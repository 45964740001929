import { Dispatch, SetStateAction } from 'react';

interface Category {
  id: string;
  name: string;
}

type Props = {
  categoryList: {
    id: string;
    name: string;
  }[];
  state: Category;
  setState: Dispatch<SetStateAction<Category>>;
};

export default function MenuCategoryPreview({ state, setState, categoryList }: Props) {
  return (
    <div className='mt-[18px]'>
      <div className='scroll w-full flex items-center overflow-x-scroll pb-[18px]'>
        {[{ id: 'all', name: '전체' }, ...categoryList].map((category, categoryIndex) => (
          <div className='relative pr-5 cursor-pointer' key={category.id}>
            <div className='absolute top-[2px] h-[1px] bg-gray1 w-full' />
            <span className='flex flex-col items-center justify-center gap-[18px]'>
              <div
                className={`w-full h-[6px] rounded-[2px] z-10 ${state.id !== category.id ? 'bg-none' : 'bg-main1'} `}
              />
              <span
                key={category.id}
                onClick={(e) => setState(category)}
                className={`text-[16px] text-nowrap ${
                  state.id === category.id ? 'text-main7 font-bold' : 'text-gray2 font-normal '
                } `}
              >
                {category.name}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
