import { ReactComponent as LanguageSvg } from 'assets/svg/global.svg';

type Props = {
  restaurantName: string;
  menuName: string;
};

export default function MenuHeaderPreview({ restaurantName, menuName }: Props) {
  return (
    <div className='w-full py-[16px] flex justify-between items-center'>
      <h3 className='font-semibold text-[16px]'>{restaurantName}</h3>
      <span className='flex items-center gap-2'>
        <h3 className='font-medium text-[14px] text-main7'>{menuName}</h3>
        <span>
          <LanguageSvg width={28} height={28} />
        </span>
      </span>
    </div>
  );
}
