import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router';
import { GetRestaurantBanners, getRestaurantBanners } from 'api/service/banner';
import { LoadingPromise } from 'lib/loading';
import BannerSkeleton from './skeleton/banner';
type Props = {};

export default function MenuBanner({}: Props) {
  const params = useParams();
  const { restaurantId } = params;
  const { data, isLoading } = useQuery<GetRestaurantBanners>({
    queryKey: ['restaurantBanners', restaurantId],
    queryFn: async () => {
      await LoadingPromise(500);
      return getRestaurantBanners(restaurantId);
    },
    enabled: !!restaurantId,
  });

  if (isLoading) {
    return <BannerSkeleton />;
  }

  const bannerList = data?.payload.filter((banner) => banner.isActivated);
  const SWIPER_AUTU_DELAY = 2000;
  return (
    <div className='px-5'>
      <Swiper
        modules={[Autoplay]}
        className='mt-[13px] aspect-[375/130] bg-white rounded-[15px]'
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{
          delay: SWIPER_AUTU_DELAY,
          disableOnInteraction: false,
        }}
        loop={bannerList.length > 0}
      >
        {bannerList.length > 0 ? (
          bannerList.map((banner) => (
            <SwiperSlide
              key={banner.id}
              className={`${banner.link ? 'cursor-pointer' : ''} bg-amber-500 rounded-[15px] bg-cover bg-center`}
              style={{
                backgroundImage: `url(${banner.image})`,
              }}
            >
              {banner.link ? (
                <Link to={banner.link} target='_blank' rel='noopener noreferrer' className='block w-full h-full'></Link>
              ) : (
                ''
              )}
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className='bg-gray-200 rounded-[15px]'>{/* 기본 회색 배너 */}</SwiperSlide>
        )}
      </Swiper>
    </div>
  );
}
