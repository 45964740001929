import { create } from 'zustand';

interface UseCategoryStore {
  state: {
    id: string;
    name: string;
  };
  setState: (state: { id: string; name: string }) => void;
}

const useCategoryStore = create<UseCategoryStore>((set) => ({
  state: { id: 'all', name: '전체' },
  setState: (state) => set(() => ({ state })),
}));

export default useCategoryStore;
