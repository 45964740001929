import { api } from 'api';

export interface GetServiceCategoryList {
  payload: GetServiceCategory[];
  error: null;
}

export interface GetServiceCategory {
  id: string;
  name: string;
  menuId: string;
  order: number;
  items: GetItems[];
}

export interface GetItems {
  id: string;
  name: string;
  image: string;
  description: string;
  categoryId: string;
  menuId: string;
  options: {
    name?: string;
    price: number;
  }[];
  tags: string[];
  order: number;
}

export const getServiceCategoryList = async (menuId: string) => {
  return await api({
    method: 'GET',
    url: `/service/menus/${menuId}/categories`,
  });
};
