import { api } from 'api';

export interface GetRestaurantMenuList {
  payload: RestaurantMenu[];
  error: null;
}

export interface RestaurantMenu {
  id: string;
  name: string;
  language: string;
  restaurantId: string;
  order: number;
}

export const getRestaurantMenuList = async (restaurantId: string) => {
  if (!restaurantId) {
    return;
  }
  return await api({
    method: 'GET',
    url: `/service/restaurants/${restaurantId}/menus`,
  });
};
