import { api } from 'api';

export interface GetRestaurantBanners {
  payload: {
    id: string;
    image: string;
    link: string;
    order: number;
    isActivated: boolean;
    restaurantId: string;
  }[];

  error: null;
}

export const getRestaurantBanners = async (restaurantId: string) => {
  if (!restaurantId) {
    return;
  }
  return await api({
    method: 'GET',
    url: `/service/restaurants/${restaurantId}/banners`,
  });
};
