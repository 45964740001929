interface GetOptions {
  price: number;
  name?: string;
}

export function getMaxPrice(options: GetOptions[]) {
  const maxPrice = options.reduce((max, option) => {
    return Math.max(max, option.price);
  }, 0);
  return maxPrice;
}

export function getMinPrice(options: GetOptions[]) {
  if (options.length === 0) return 0;

  const price = options.map((option) => option.price);
  const minPrice = Math.min(...price);
  return minPrice;
}

export function hasDifferentPrices(options: GetOptions[]): boolean {
  if (options.length <= 1) return false;

  const firstPrice = options[0].price;
  return options.some((option) => option.price !== firstPrice);
}
