import { ReactComponent as LanguageSvg } from 'assets/svg/global.svg';
import { Link, useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { GetRestaurant, getRestaurant } from 'api/service/restaurant';
import { GetRestaurantMenuList, getRestaurantMenuList } from 'api/service/menu';
import { getLanguageDisplay } from 'lib/language';

type Props = {};

export default function MenuHeader({}: Props) {
  const params = useParams();
  const { restaurantId, menuId } = params;
  const { data: restaurant, isLoading: restaurantLoading } = useQuery<GetRestaurant>({
    queryKey: ['service/restaurant', restaurantId],
    queryFn: () => getRestaurant(restaurantId),
    enabled: !!restaurantId,
  });

  const { data: menuList, isLoading: menuListLoading } = useQuery<GetRestaurantMenuList>({
    queryKey: ['service/restaurant/menus', restaurantId],
    queryFn: () => getRestaurantMenuList(restaurantId),
    enabled: !!restaurantId,
  });
  const selectedMenu = menuList?.payload.find((menu) => menu.id === menuId);

  if (menuListLoading || restaurantLoading) {
    return (
      <div className='w-full py-[16px] px-[20px] flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <div className='h-[28px] w-[28px] bg-gray1 rounded-full animate-pulse' />
          <div className='h-[24px] w-[120px] bg-gray1 rounded-md animate-pulse' />
        </div>

        <div className='flex items-center gap-2'>
          <div className='h-[20px] w-[80px] bg-gray1 rounded-md animate-pulse' />
          <div className='h-[28px] w-[28px] bg-gray1 rounded-full animate-pulse' />
        </div>
      </div>
    );
  }

  return (
    <div className='w-full py-[16px] px-[20px] flex justify-between items-center'>
      <span className='flex items-center gap-2'>
        {restaurant?.payload.logo ? (
          <img
            src={restaurant?.payload.logo}
            alt={restaurant?.payload.name}
            className='w-[28px] h-[28px] rounded-full'
          />
        ) : (
          <span className='w-[28px] h-[28px] bg-gray1 rounded-full' />
        )}
        <h3 className='font-semibold text-[16px]'>{restaurant?.payload.name}</h3>
      </span>
      <span className='flex items-center gap-2'>
        <h3 className='font-medium text-[14px] text-main7'>
          {selectedMenu?.language === 'ko' ? selectedMenu?.name : getLanguageDisplay(selectedMenu?.language)}
        </h3>
        <Link to={`/restaurant/${restaurantId}/menus`}>
          <LanguageSvg width={28} height={28} />
        </Link>
      </span>
    </div>
  );
}
