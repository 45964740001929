import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { useState } from 'react';
import { PASSWORD_REGEX, PASSWORD_REGEX_ERROR } from 'lib/constants';
import useModalStore from 'store/useModalStore';
import Loader from 'components/common/Loader';
import { errorHandler } from 'lib/error';
import { useTranslation } from 'react-i18next';

type Props = {};

export const checkPasswords = ({ newPassword, confirmPassword }: { newPassword: string; confirmPassword: string }) =>
  newPassword === confirmPassword;

const createFindPasswordSchema = (t: (key: string) => string) =>
  z
    .object({
      email: z.string().email({ message: t('validation.emailRequired') }),
      authCode: z
        .string()
        .trim()
        .min(1, { message: t('validation.authCodeRequired') })
        .optional(),
      oldPassword: z.string().trim().regex(PASSWORD_REGEX, t('validation.passwordRegex')).optional(),
      newPassword: z.string().trim().regex(PASSWORD_REGEX, t('validation.passwordRegex')).optional(),
      confirmPassword: z
        .string()
        .trim()
        .min(1, { message: t('validation.passwordRequired') })
        .optional(),
    })
    .refine(checkPasswords, {
      message: t('validation.passwordMismatch'),
      path: ['confirmPassword'],
    });

interface IsCodeVerification {
  send: boolean;
  certification: boolean;
}

interface PasswordResetResponse {
  payload: null;
  error: null;
}

export default function FindPassword({}: Props) {
  const navigate = useNavigate();
  const { setOneBtnModal } = useModalStore();
  const { t } = useTranslation();
  const findPasswordSchema = createFindPasswordSchema(t);
  const [isCodeVerification, setIsCodeVerification] = useState<IsCodeVerification>({
    send: false,
    certification: false,
  });

  const { mutate, isPending } = useMutation<any, ApiError, ApiProps>({ mutationFn: api });
  const { mutate: resetPasswordMutate, isPending: resetPasswordPending } = useMutation<
    PasswordResetResponse,
    ApiError,
    ApiProps
  >({ mutationFn: api });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm<z.infer<typeof findPasswordSchema>>({
    resolver: zodResolver(findPasswordSchema),
  });

  const onSubmit = (data: z.infer<typeof findPasswordSchema>) => {
    const email = getValues('email');
    const authCode = getValues('authCode');
    const oldPassword = getValues('oldPassword');
    const newPassword = getValues('newPassword');
    setIsCodeVerification((state) => ({ ...state, send: true }));
    if (isChangePassword) {
      resetPasswordMutate(
        {
          method: 'PATCH',
          url: '/admin/auth/password/reset',
          data: {
            email,
            authCode,
            oldPassword,
            newPassword,
          },
        },
        {
          onSuccess(data, variables, context) {
            setOneBtnModal(t('message.alert'), t('findPassword.비밀번호변경성공'), () => navigate('/admin/login'));
          },
          onError(error, variables, context) {
            errorHandler(t, error, setOneBtnModal);
          },
        }
      );
    } else {
      isCodeVerification.send ? checkCode() : onSendCode(email);
    }
  };

  // 인증 요청
  const onSendCode = (email: string) => {
    setIsCodeVerification((state) => ({ ...state, send: true }));
    mutate(
      {
        method: 'POST',
        url: '/admin/auth/code',
        data: {
          email: email,
        },
      },
      {
        onSuccess(data, variables, context) {
          setIsCodeVerification((state) => ({ ...state, send: true }));
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  // 인증 확인
  const checkCode = () => {
    const email = getValues('email');
    const authCode = getValues('authCode');

    mutate(
      {
        method: 'POST',
        url: '/admin/auth/code/verification',
        data: {
          email,
          authCode,
        },
      },
      {
        onSuccess(data, variables, context) {
          setIsCodeVerification({
            send: true,
            certification: true,
          });
        },
        onError(error, variables, context) {
          setError('authCode', {
            type: 'custom',
            message: t('validation.authCodeMismatch'),
          });
        },
      }
    );
  };

  const getButtonText = (isPending: boolean, isChangePassword: boolean, isCodeVerification: IsCodeVerification) => {
    if (isPending) return t('message.processing');
    if (isChangePassword) return t('findPassword.비밀번호변경');
    return isCodeVerification.send ? t('message.certificationComplete') : t('message.certificationRequest');
  };

  const isChangePassword = isCodeVerification.send && isCodeVerification.certification;
  return (
    <div className='flex flex-col justify-center items-center gap-[50px] w-full h-full'>
      {(isPending || resetPasswordPending) && <Loader />}
      <LogoSvg width={88} height={18} className='hidden md:block' />
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col max-w-[335px] w-full gap-[15px]'>
        <AuthInput
          {...register('email')}
          error={errors.email?.message}
          placeholder={t('findPassword.이메일')}
          required={true}
          type='text'
          hidden={isChangePassword}
        />
        {isCodeVerification.send ? (
          <AuthInput
            {...register('authCode')}
            error={errors.authCode?.message}
            placeholder={t('findPassword.인증번호')}
            required={true}
            type='text'
            hidden={isChangePassword}
          />
        ) : null}

        {isChangePassword ? (
          <>
            <AuthInput
              {...register('oldPassword')}
              error={errors.oldPassword?.message}
              placeholder={t('findPassword.기존비밀번호')}
              required={true}
              type='password'
            />
            <AuthInput
              {...register('newPassword')}
              error={errors.newPassword?.message}
              placeholder={t('findPassword.새비밀번호')}
              required={true}
              type='password'
            />
            <AuthInput
              {...register('confirmPassword')}
              error={errors.confirmPassword?.message}
              placeholder={t('findPassword.비밀번호확인')}
              required={true}
              type='password'
            />
          </>
        ) : null}
        <AuthButton
          style={`${isPending ? 'bg-gray1 text-white' : 'bg-black text-white'} mt-[31px]`}
          text={getButtonText(isPending, isChangePassword, isCodeVerification)}
          type='submit'
          disabled={isPending}
        />

        <Link to={'/admin/login'}>
          <AuthButton style='bg-gray1 text-gray4' text={t('findPassword.로그인')} type='button' />
        </Link>
      </form>
    </div>
  );
}
