import MoreSelect from 'components/common/MoreSlect';
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import { CategoryItems } from 'api/admin/cateogry';
import useModalStore from 'store/useModalStore';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { errorHandler } from 'lib/error';

type Props = {
  item: CategoryItems;
  categoryName: string;
};

export default function DraggableMenu({ item, categoryName }: Props) {
  const { setCreateMenuModal } = useModalStore();
  const { t } = useTranslation();
  const { setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();
  const queryClient = useQueryClient();

  const { mutate: deleteMenu } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const deleteMenuHandler = () => {
    deleteMenu(
      {
        url: `/admin/items/${item.id}`,
        method: 'DELETE',
      },
      {
        onSuccess: () => {
          setCloseTwoBtnModal();
          setOneBtnModal(t('message.alert'), t('menu.메뉴를삭제했습니다'), () =>
            queryClient.invalidateQueries({ queryKey: ['categories'] })
          );
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  return (
    <div className={`border border-gray1 rounded-sm py-2 px-1 flex justify-between items-center`}>
      <span className='flex items-center justify-center'>
        <span className='cursor-move hover:bg-gray1 p-2 rounded-sm'>
          <MenuIcon />
        </span>
        <span className='text-[14px] font-semibold pl-1'>{item.name}</span>
      </span>
      <span>
        <MoreSelect
          list={[
            {
              label: t('menu.수정하기'),
              name: t('menu.수정하기'),
              onClick: () => {
                setCreateMenuModal(item.categoryId, categoryName, item.menuId, item);
              },
            },
            {
              label: t('menu.삭제하기'),
              name: t('menu.삭제하기'),
              onClick: () => {
                setTwoBtnModal({
                  title: t('message.alert'),
                  contents: t('menu.메뉴를삭제하시겠습니까'),
                  leftText: t('menu.취소'),
                  rightText: t('menu.삭제'),
                  leftFn: setCloseTwoBtnModal,
                  rightFn: deleteMenuHandler,
                });
              },
            },
          ]}
        />
      </span>
    </div>
  );
}
