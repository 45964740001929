import useModalStore from 'store/useModalStore';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';
import ModalLayout from 'components/modal/ModalLayout';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { LoadingPromise } from 'lib/loading';
import { useEffect, useState } from 'react';
import useTagsStore from 'store/service/useTagsStore';
import { CheckIcon } from '@heroicons/react/24/solid';
import SettingSkeleton from './skeleton/setting';
import { fetchTags, TagResponse } from 'api/service/tag';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function SettingModal({}: Props) {
  const { modals, setModals } = useModalStore();
  const { menuId } = useParams();
  const { t } = useTranslation();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const { state, setState } = useTagsStore();

  const { data: tags, isLoading: tagsLoading } = useQuery<TagResponse>({
    queryKey: ['service/tags', menuId],
    queryFn: async () => {
      await LoadingPromise(500);
      return fetchTags(menuId);
    },
    enabled: !!menuId,
  });

  const closeModal = () => setModals('menuSettingModal');

  const handleTagToggle = (tag: string) => {
    setSelectedTags((prev) => (prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]));
  };

  const handleSave = () => {
    setState(selectedTags);
    closeModal();
  };

  useEffect(() => {
    if (state.tags.length > 0) {
      setSelectedTags(state.tags);
    }
  }, [modals.menuSettingModal.isOpen]);

  return (
    <ModalLayout isOpen={modals.menuSettingModal.isOpen} onClose={closeModal}>
      {tagsLoading ? (
        <SettingSkeleton />
      ) : (
        <div className='h-full flex flex-col justify-between items-center '>
          <div className='w-full flex items-center justify-center relative'>
            <button className='p-2 absolute left-0' onClick={closeModal}>
              <BackIcon fill='#9C9C9C' />
            </button>
            <h2 className='text-base font-bold'>{t('service.메뉴설정')}</h2>
          </div>
          <div className='w-full px-5 overflow-scroll py-8'>
            <div className='flex flex-col gap-2'>
              {tags?.payload?.map((tag) => (
                <label key={tag} className='flex items-center gap-4 cursor-pointer'>
                  <input
                    type='checkbox'
                    className='hidden'
                    checked={selectedTags.includes(tag)}
                    onChange={() => handleTagToggle(tag)}
                  />
                  <span
                    className={`w-5 h-5 border rounded-full  flex items-center justify-center ${
                      selectedTags.includes(tag) ? 'bg-main1 border-main1' : 'bg-white border-gray1'
                    }`}
                  >
                    <CheckIcon className='w-4 h-4 text-white' />
                  </span>
                  <span>{tag}</span>
                </label>
              ))}
            </div>
          </div>

          <div className='w-full bg-white'>
            <button onClick={handleSave} className='bg-main1 w-full py-3 font-bold text-white text-lg rounded-lg'>
              {t('service.설정')}
            </button>
          </div>
        </div>
      )}
    </ModalLayout>
  );
}
