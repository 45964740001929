import { GetItems } from 'api/service/category';
import { getMaxPrice, getMinPrice, hasDifferentPrices } from 'lib/meun';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  items: GetItems[] | [];
};

export default function MenuListPreview({ name, items }: Props) {
  const { t } = useTranslation();
  return (
    <div className='h-[330px] overflow-y-scroll'>
      <h3 className='text-[12px] font-semibold mt-[13px] mb-[10px]'>{name}</h3>

      {items?.map((item) => (
        <span key={item.id} className='flex items-center mb-3'>
          <div className='bg-gray1 overflow-hidden rounded-[10px] aspect-[100/107] min-w-[80px] max-w-[80px] w-full mr-4'>
            <img src={item.image} alt={item.name} className='w-full h-full object-cover' />
          </div>
          <div className=''>
            <h2 className='text-[14px] font-bold mb-2'>{item.name}</h2>
            {hasDifferentPrices(item.options) ? (
              <div className='text-sm text-main6 font-bold mb-2'>
                <span className='leading-[9.42px] bg-yellow2 rounded-[4px]'>
                  {`${Number(getMinPrice(item.options)).toLocaleString()} ${t('menuPreview.원')}`}
                </span>
                <span>{` ~ ${Number(getMaxPrice(item.options)).toLocaleString()} ${t('menuPreview.원')}`}</span>
              </div>
            ) : (
              <span className='text-sm text-main6 font-bold mb-2 leading-[9.42px] bg-yellow2 rounded-[4px]'>{`${Number(
                getMaxPrice(item.options)
              ).toLocaleString()} ${t('menuPreview.원')}`}</span>
            )}
            <p className='line-clamp-2 text-[11px] font-light'>{item.description}</p>
          </div>
        </span>
      ))}
    </div>
  );
}
