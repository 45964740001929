import { QRCodeCanvas as QRCode } from 'qrcode.react';
import QRCodeIcon from 'assets/svg/qr-icon.svg';
import { useRef } from 'react';
import Button from 'components/style/Button';
import { getCookie } from 'api/cookie';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function Dashboard({}: Props) {
  const qrRef = useRef<HTMLCanvasElement | null>(null);
  const restaurantId = getCookie('restaurantId');
  const { t } = useTranslation();

  // const downloadQRCode = () => {
  //   if (qrRef.current) {
  //     const link = document.createElement('a');
  //     link.href = qrRef.current.toDataURL('image/png'); // PNG 형식으로 변환
  //     link.download = 'qr-code.png'; // 다운로드할 파일 이름
  //     link.click();
  //   }
  // };
  const downloadQRCode = () => {
    if (qrRef.current) {
      // 고해상도로 QR 코드 생성
      const canvas = qrRef.current;
      const scale = 4; // 해상도를 4배로 증가
      const scaledCanvas = document.createElement('canvas');
      const ctx = scaledCanvas.getContext('2d');

      scaledCanvas.width = canvas.width * scale;
      scaledCanvas.height = canvas.height * scale;

      if (ctx) {
        ctx.scale(scale, scale);
        ctx.drawImage(canvas, 0, 0);

        const link = document.createElement('a');
        link.href = scaledCanvas.toDataURL('image/png', 1.0); // 최대 품질로 설정
        link.download = 'qr-code.png';
        link.click();
      }
    }
  };

  return (
    <div className='w-full flex flex-col md:px-[20px] md:pl-[32px]'>
      <h2 className='text-xl font-bold mb-[32px] md:mb-[48px] text-center md:text-left'>{t('dashboard.메뉴QR코드')}</h2>

      <div className='flex flex-col items-center md:items-start md:flex-row gap-14 w-full'>
        {/* QR */}
        <section className='flex-1'>
          <div>
            <QRCode
              ref={qrRef}
              value={`https://onnuon.com/restaurant/${restaurantId}/menus`}
              bgColor='#ffffff'
              fgColor='#000000'
              level='H'
              minVersion={3}
              size={512}
              imageSettings={{
                src: QRCodeIcon,
                x: undefined,
                y: undefined,
                height: 68,
                width: 68,
                opacity: 1,
                excavate: true,
              }}
              style={{
                width: '100%',
                maxWidth: '196px',
                height: 'auto',
                aspectRatio: '1',
              }}
            />
          </div>
        </section>

        {/* QR Code download btn && 레스토랑 메뉴판 주소 */}
        <section className='flex-[5] flex flex-col gap-8 w-full'>
          <Button
            onClick={downloadQRCode}
            text={t('dashboard.QR코드다운로드')}
            style='bg-main3 hover:bg-main1 text-white'
          />
          <Link
            to={`/restaurant/${restaurantId}/menus`}
            target='_blank'
            className='flex items-center justify-center w-full opacity-80 hover:opacity-100 h-[48px] text-[16px] rounded-[10px] transition-colors duration-200 bg-main3 hover:bg-main1 text-white'
          >
            {t('dashboard.레스토랑메뉴주소')}
          </Link>
          {/* <Textarea placeholder='이거 뭐였지?..' style='h-[340px]' readonly /> */}
        </section>

        {/* 고객이 많이 눌러본 TOP 5 && 고객이 많이 하트 누른 TOP 5 */}
        {/* <section className='flex-[5] flex flex-col gap-4'>
        <div>
          <label className='text-[14px] font-bold mb-3 inline-block'>인기 조회 메뉴 TOP 5</label>
          <Textarea
            placeholder={'연어회\n모듬회\n매운탕\n새우튀김\n꽃게튀김'}
            style='h-[200px] placeholder:text-gray-700 placeholder:font-semibold'
            readonly
          />
        </div>

        <div>
          <label className='text-[14px] font-bold mb-3 inline-block'>좋아요 많은 메뉴 TOP 5</label>
          <Textarea
            placeholder={'연어회\n모듬회\n매운탕\n새우튀김\n꽃게튀김'}
            style='h-[200px] placeholder:text-gray-700 placeholder:font-semibold'
            readonly
          />
        </div>
      </section> */}
      </div>
    </div>
  );
}
