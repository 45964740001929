import React, { ButtonHTMLAttributes } from 'react';

type Props = {
  style?: string;
  text: string;
};

export default function Button({ style, text, ...rest }: ButtonHTMLAttributes<HTMLButtonElement> & Props) {
  return (
    <button
      {...rest}
      className={`w-full h-[48px] font-bold text-[16px] text-center rounded-[10px] opacity-80 hover:opacity-100 transition-colors duration-200 ${style}`}
    >
      {text}
    </button>
  );
}
