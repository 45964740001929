import { useQuery } from '@tanstack/react-query';
import { ApiError } from 'api';
import { fetchRestaurants, Restaurants } from 'api/admin/restaurants';
import { removeCookie, setCookie } from 'api/cookie';
import Loader from 'components/common/Loader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type Props = {};

export default function Home({}: Props) {
  const navigate = useNavigate();
  const { data, error } = useQuery<Restaurants, ApiError>({
    queryKey: ['restaurantList'],
    queryFn: fetchRestaurants,
  });

  useEffect(() => {
    if (error) {
      if (error.error.code === 'E01003') {
        removeCookie('accessToken');
        removeCookie('refreshToken');
        removeCookie('restaurantId');
        navigate('/admin/login');

        return;
      }
    }

    if (data) {
      setCookie('restaurantId', data?.payload[0].id);
      navigate(`/admin/dashboard/${data?.payload[0].id}`);
    }
  }, [data, error]);

  return <Loader />;
}
