import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { ReactComponent as GlobalIcon } from 'assets/svg/global.svg';
import useModalStore from 'store/useModalStore';
import LanguageModal from '../modal/LanguageModal';
import OneBtnModal from '../modal/OneBtnModal';
import TwoBtnModal from '../modal/TwoBtnModal';
import { useEffect } from 'react';
import { getCookie } from 'api/cookie';

type Props = {};

export default function AdminBeforeLoginLayout({}: Props) {
  const navigate = useNavigate();
  const { oneBtnModal, twoBtnModal, modals, setModals } = useModalStore();
  useEffect(() => {
    const isLogin = getCookie('accessToken');

    if (isLogin) {
      navigate('/admin');
    }
  }, []);

  return (
    <>
      {modals.languageModal.isOpen && <LanguageModal />}
      {oneBtnModal.isOpen && (
        <OneBtnModal contents={oneBtnModal.alertMsg} title={oneBtnModal.title} onClick={oneBtnModal.onClick} />
      )}
      {twoBtnModal.isOpen && (
        <TwoBtnModal
          title={twoBtnModal.title}
          contents={twoBtnModal.contents}
          leftText={twoBtnModal.leftText}
          leftFn={twoBtnModal.leftFn}
          rightText={twoBtnModal.rightText}
          rightFn={twoBtnModal.rightFn}
        />
      )}

      <div className='flex flex-col h-[100dvh] px-[20px] md:px-[120px]'>
        <div className='flex items-start justify-between pt-[30px] border-b-[1px] border-gray1'>
          <span>
            <Link to='/'>
              <LogoSvg className='w-[60px] h-[16px] md:w-[88px] md:h-[22px]' />
            </Link>
            <div className='mt-[20px] h-[3px] md:h-[7px] bg-main1  rounded-[2px]' />
          </span>
          <span className='flex items-center gap-4'>
            <span
              className='text-main7 text-[13px] font-NEXONFootballGothicBA1 cursor-pointer'
              onClick={() => setModals('languageModal')}
            >
              Language
            </span>
            <GlobalIcon />
          </span>
        </div>
        <Outlet />
      </div>
    </>
  );
}
