import { ReactComponent as FilterIcon } from 'assets/svg/filter-square.svg';
import SettingModal from './SettingModal';
import useModalStore from 'store/useModalStore';
import useTagsStore from 'store/service/useTagsStore';
import { useTranslation } from 'react-i18next';

type Props = {};

export default function MenuSettings({}: Props) {
  const { setModals } = useModalStore();
  const { state } = useTagsStore();
  const { t } = useTranslation();

  return (
    <>
      {<SettingModal />}

      <div className='mx-[20px] pb-5 border-b border-b-gray1'>
        <div
          className='w-full mt-[12px] gap-1 flex items-center justify-start flex-wrap'
          onClick={() => setModals('menuSettingModal')}
        >
          {state.tags.length > 0 ? (
            // <span className='w-full text-center font-bold font-NEXONLv1GothicOTF'>{state.tags.join(', ')}</span>
            <>
              {state.tags.map((tag, index) => (
                <span
                  className='text-sm bg-gray1 rounded-[5.5px] px-2 py-1 font-bold font-NEXONLv1GothicOTF'
                  key={index}
                >
                  {`#${tag}`}
                </span>
              ))}
            </>
          ) : (
            <div className='flex items-center w-full'>
              <FilterIcon />
              <span className='w-full text-center font-bold font-NEXONLv1GothicOTF'>{t('service.맞춤설정하세요')}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
