import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { BannerPayload, BannersResponse, fetchBanners } from 'api/admin/banner';
import { getCookie } from 'api/cookie';
import BannerDetailModal from 'components/banner/BannerDetailModal';
import Toggle from 'components/common/Toggle';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { errorHandler } from 'lib/error';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import useModalStore from 'store/useModalStore';

type Props = {};

export default function Banners({}: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [bannerId, setBannerId] = useState<string | null>(null);
  const { modals, setModals, setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();
  const restaurantId = getCookie('restaurantId');
  const { data } = useQuery<BannersResponse>({
    queryKey: ['bannerList', restaurantId],
    queryFn: () => fetchBanners(restaurantId),
    enabled: !!restaurantId,
  });

  const { mutate: removeBannerMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const removeBanner = (bannerId: string) => {
    if (!bannerId) {
      return;
    }

    removeBannerMutate(
      {
        url: `/admin/banners/${bannerId}`,
        method: 'DELETE',
      },
      {
        onSuccess(data, variables, context) {
          setOneBtnModal(t('message.alert'), t('banner.배너를삭제하였습니다'), () =>
            queryClient.invalidateQueries({ queryKey: ['bannerList'] })
          );
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
        onSettled(data, error, variables, context) {
          setCloseTwoBtnModal();
        },
      }
    );
  };

  const { mutate: toggleBannerMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const handleToggle = (bannerInfo: BannerPayload) => {
    toggleBannerMutate(
      {
        method: 'PUT',
        url: `/admin/banners/${bannerInfo.id}`,
        data: {
          isActivated: !bannerInfo.isActivated,
        },
      },
      {
        onSuccess(data, variables, context) {
          queryClient.invalidateQueries({ queryKey: ['bannerList'] });
        },
      }
    );
  };

  const { mutate: bannerReorderMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const { currentItems, handleDragStart, handleDragOver, handleDrop, setItems, dragOverIndex } = useDragAndDrop({
    items: [],
    onReorder: (startId, endId) => {
      bannerReorderMutate({
        method: 'PATCH',
        url: `/admin/banners/order`,
        data: {
          bannerIds: [startId, endId],
        },
      });
    },
  });

  useEffect(() => {
    if (data?.payload) {
      setItems(data.payload);
    }
  }, [data]);

  return (
    <div className='w-full flex flex-col md:px-[20px]'>
      {modals.bannerDetailModal.isOpen && <BannerDetailModal bannerId={bannerId} setBannerId={setBannerId} />}
      <div className='flex justify-between items-center mb-[32px] md:mb-[48px]'>
        <h2 className='text-xl font-bold'>{t('banner.배너관리')}</h2>
        <button
          onClick={() => {
            setBannerId(null);
            setModals('bannerDetailModal');
          }}
          className='text-[12px] md:text-[16px] bg-main1 text-white rounded-xl shadow-lg py-2 px-4'
        >
          {t('banner.배너생성')}
        </button>
      </div>

      <section className='flex flex-col gap-4 md:bg-neutral-50 pb-[30px] rounded-xl h-full md:overflow-scroll md:h-[900px]'>
        {/* 데스크탑 뷰에서만 보이는 헤더 */}
        <li className='flex-row justify-between items-center pb-[25px] font-bold *:text-center hidden lg:flex sticky top-0 bg-accent1 text-white pt-[30px]'>
          <span className='flex-[0.6]'>{t('banner.번호')}</span>
          <span className='flex-[2]'>{t('banner.배너이미지')}</span>
          <span className='flex-[1.3]'>{t('banner.배너Url')}</span>
          <span className='flex-1'>{t('banner.배너활성화')}</span>
          <span className='flex-[1.5]'></span>
        </li>

        {/* 컨텐츠 */}
        <ul className='flex flex-col gap-6 md:gap-0 h-full md:h-[800px] overflow-y-auto'>
          {currentItems?.map((banner, index) => (
            <li
              key={index}
              draggable
              onDragStart={() => handleDragStart(index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDrop={() => handleDrop(index)}
              className={`cursor-grab md:flex flex-col md:flex-row justify-between items-center *:text-center text-gray-500 bg-neutral-50 p-4 md:px-0 py-4 border rounded-lg md:border-none
                  transition-all duration-300 active:scale-[1.02] active:shadow-md ${
                    dragOverIndex === index ? 'border-2 border-main bg-gray-200 scale-[1.02] shadow-md' : ''
                  }
                  `}
            >
              <span className='flex-[0.6] mb-2 md:mb-0 font-bold hidden md:block'>{index + 1}</span>
              <span className='flex-[2] flex justify-center items-center mb-4 md:mb-0'>
                <img
                  src={banner.image}
                  alt='banner'
                  className='w-full md:w-[90%] aspect-video rounded-lg object-cover object-center'
                />
              </span>

              {/* PC */}
              {banner.link ? (
                <Link
                  className='flex-[1.3] mb-4 md:mb-0 hover:text-gray-950 transition-colors duration-300 break-all hidden md:block'
                  to={banner.link}
                  target='_blank'
                >
                  {banner.link}
                </Link>
              ) : (
                <span className='flex-[1.3] mb-4 md:mb-0 hidden md:block'>-</span>
              )}
              <span className='flex-1 mb-4 md:mb-0 w-full md:w-auto hidden md:block'>
                <Toggle isOn={banner.isActivated} setOn={() => handleToggle(banner)} />
              </span>

              {/* Mobile */}
              <div className='w-full mb-4 flex justify-between items-center md:hidden'>
                {banner.link ? (
                  <Link
                    className='mb-4 hover:text-gray-950 transition-colors duration-300 break-all w-full flex justify-start items-center'
                    to={banner.link}
                    target='_blank'
                  >
                    {banner.link}
                  </Link>
                ) : (
                  <span className='mb-4'>-</span>
                )}
                <span className='flex-1 mb-4 w-full'>
                  <Toggle isOn={banner.isActivated} setOn={() => handleToggle(banner)} />
                </span>
              </div>
              <span className='flex-[1.5] flex justify-center md:justify-around gap-4 md:gap-1 transition-colors duration-300 w-full md:w-auto'>
                <button
                  className='rounded-lg p-3 w-full md:w-auto bg-main2 text-white hover:bg-main1 hover:text-white transition-colors duration-300'
                  onClick={() => {
                    setBannerId(banner.id);
                    setModals('bannerDetailModal');
                  }}
                >
                  {t('banner.상세보기')}
                </button>
                <button
                  className='rounded-lg p-3 w-full md:w-auto bg-gray1 text-neutral-400 hover:bg-main1 hover:text-white transition-colors duration-300'
                  onClick={() => {
                    setTwoBtnModal({
                      title: t('message.alert'),
                      contents: t('banner.정말로배너를삭제하시겠습니까'),
                      leftText: t('message.cancel'),
                      rightText: t('message.delete'),
                      leftFn: () => setCloseTwoBtnModal(),
                      rightFn: () => removeBanner(banner.id),
                    });
                  }}
                >
                  {t('message.delete')}
                </button>
              </span>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
