import Unlisted from 'components/menu/Unlisted';

type Props = {};

/**
 * 메뉴판명
 * 수동 작성 (언어선택)
 * 엑셀 업로드
 */

export default function MenuCreate({}: Props) {
  return (
    <div className='flex w-full'>
      <Unlisted />
    </div>
  );
}
