import AuthInput from 'components/style/AuthInput';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import AuthButton from 'components/style/AuthButton';
import { Link, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import validator from 'validator';
import { PASSWORD_REGEX } from 'lib/constants';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import useModalStore from 'store/useModalStore';
import Loader from 'components/common/Loader';
import { errorHandler } from 'lib/error';
import { useTranslation } from 'react-i18next';

type Props = {};

const checkPasswords = ({ password, confirmPassword }: { password: string; confirmPassword: string }) =>
  password === confirmPassword;

const createSignUpSchema = (t: (key: string) => string) => {
  return z
    .object({
      email: z.string().email({ message: t('validation.emailRequired') }),
      authCode: z
        .string()
        .trim()
        .min(1, { message: t('validation.authCodeRequired') }),
      password: z.string().trim().regex(PASSWORD_REGEX, t('validation.passwordRegex')),
      confirmPassword: z
        .string()
        .trim()
        .min(1, { message: t('validation.passwordRequired') }),
      restaurantName: z
        .string()
        .trim()
        .min(1, { message: t('validation.restaurantNameRequired') }),
      restaurantPhone: z
        .string()
        .trim()
        .min(1, { message: t('validation.restaurantPhoneRequired') }),
      managerPhone: z
        .string()
        .trim()
        .refine((phone) => validator.isMobilePhone(phone, 'ko-KR'), {
          message: t('validation.invalidManagerPhone'),
        }),
    })
    .refine(checkPasswords, {
      message: t('validation.passwordMismatch'),
      path: ['confirmPassword'],
    });
};

export default function SignUp({}: Props) {
  const { t } = useTranslation();
  const signUpSchema = createSignUpSchema(t);
  const navigate = useNavigate();
  const { setOneBtnModal } = useModalStore();
  const { mutate, isPending } = useMutation<any, ApiError, ApiProps>({ mutationFn: api });
  const [isCodeVerification, setIsCodeVerification] = useState({
    send: false,
    certification: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
  });

  // 인증 요청
  const onSendCode = () => {
    const email = getValues('email');
    if (!validator.isEmail(email)) {
      setError('email', {
        type: 'custom',
        message: t('signUp.유효하지않은이메일입니다'),
      });
      return;
    } else {
      clearErrors();

      if (isCodeVerification.send) {
        setIsCodeVerification((state) => {
          return { certification: false, send: false };
        });
        setValue('authCode', '');
      } else {
        mutate(
          {
            method: 'POST',
            url: '/admin/auth/code',
            data: {
              email: getValues('email'),
            },
          },
          {
            onSuccess(data, variables, context) {
              setIsCodeVerification((state) => {
                return { ...state, send: true };
              });
            },
            onError(error, variables, context) {
              errorHandler(t, error, setOneBtnModal);
            },
          }
        );
      }
    }
  };

  // 인증 확인
  const checkCode = () => {
    if (isCodeVerification?.certification) {
      return;
    }

    const email = getValues('email');
    const authCode = getValues('authCode');

    if (authCode.length > 0) {
      clearErrors();
      mutate(
        {
          method: 'POST',
          url: '/admin/auth/code/verification',
          data: {
            email,
            authCode,
          },
        },
        {
          onSuccess(data, variables, context) {
            setIsCodeVerification({
              certification: true,
              send: true,
            });
          },
          onError(error, variables, context) {
            setError('authCode', {
              type: 'custom',
              message: t('signUp.인증번호가일치하지않습니다'),
            });
          },
        }
      );
    } else {
      setError('authCode', {
        type: 'custom',
        message: t('signUp.인증번호를입력해주세요'),
      });
    }
  };

  // 회원가입
  const onSubmit = (data: z.infer<typeof signUpSchema>) => {
    const { email, authCode, confirmPassword, managerPhone, password, restaurantName, restaurantPhone } = data;
    mutate(
      {
        method: 'POST',
        url: '/admin/auth/sign-up',
        data: {
          email,
          password,
          restaurantName,
          restaurantPhone,
          managerPhone,
          authCode,
        },
      },
      {
        onSuccess(data, variables, context) {
          setOneBtnModal('안내', t('signUp.회원가입성공'), () => {
            navigate('/admin/login');
          });
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  return (
    <div className='flex flex-col justify-center items-center gap-[50px] w-full h-full'>
      {isPending && <Loader />}

      <LogoSvg width={88} height={18} className='hidden md:block' />

      <form className='flex flex-col gap-[15px] max-w-[335px] w-full' onSubmit={handleSubmit(onSubmit)}>
        {/* 이메일 */}
        <div className='w-full'>
          <div className='flex items-center justify-between w-full gap-3'>
            <AuthInput
              {...register('email')}
              placeholder={t('signUp.이메일')}
              required={true}
              type='email'
              readOnly={isCodeVerification?.send}
            />
            <button
              type='button'
              className='min-w-[100px] h-full py-2 px-4 font-semibold text-[16px] rounded-lg bg-gray2 text-white'
              onClick={onSendCode}
            >
              {isCodeVerification?.send ? t('signUp.재요청') : t('signUp.인증요청')}
            </button>
          </div>
          {errors.email?.message && (
            <span className='text-red-500 text-[10px] md:text-[12px] mt-1 pl-1'>{errors.email?.message}</span>
          )}
        </div>
        {/* 인증 코드 */}
        {isCodeVerification.send && (
          <div className='w-full'>
            <div className='flex items-center justify-between w-full gap-3'>
              <AuthInput
                {...register('authCode')}
                placeholder={t('signUp.인증번호')}
                required={true}
                type='string'
                readOnly={isCodeVerification?.certification}
              />
              <button
                type='button'
                className='min-w-[100px] h-full py-2 px-4 font-semibold text-[16px] rounded-lg bg-gray2 text-white'
                onClick={checkCode}
              >
                {isCodeVerification?.certification ? t('signUp.인증완료') : t('signUp.인증확인')}
              </button>
            </div>
            {errors.authCode?.message && (
              <span className='text-red-500 text-[10px] md:text-[12px] mt-1 pl-1'>{errors.authCode?.message}</span>
            )}
          </div>
        )}
        <AuthInput
          {...register('password')}
          placeholder={t('signUp.비밀번호')}
          required={true}
          type='password'
          error={errors.password?.message}
        />
        <AuthInput
          {...register('confirmPassword')}
          placeholder={t('signUp.비밀번호확인')}
          required={true}
          type='password'
          error={errors.confirmPassword?.message}
        />
        <AuthInput
          {...register('restaurantName')}
          placeholder={t('signUp.레스토랑이름')}
          required={true}
          type='text'
          error={errors.restaurantName?.message}
        />
        <AuthInput
          {...register('restaurantPhone')}
          placeholder={t('signUp.레스토랑연락처')}
          required={true}
          type='text'
          error={errors.restaurantPhone?.message}
        />
        <AuthInput
          {...register('managerPhone')}
          placeholder={t('signUp.담당자연락처')}
          required={true}
          type='text'
          error={errors.managerPhone?.message}
        />

        <AuthButton style='mt-[21px] bg-lightGreen1 text-gray4' text={t('signUp.가입하기')} type='submit' />
        <Link to={'/admin/login'}>
          <AuthButton style='mt-[1px] bg-gray1 text-gray4' text={t('signUp.로그인')} type='button' />
        </Link>
      </form>
    </div>
  );
}
