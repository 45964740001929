import MoreSelect from 'components/common/MoreSlect';
import { ReactComponent as CheckDownIcon } from 'assets/svg/chevron-down.svg';
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import DraggableMenu from './DraggableMenu';
import useModalStore from 'store/useModalStore';
import { Category } from 'api/admin/cateogry';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, ApiError, ApiProps } from 'api';
import { errorHandler } from 'lib/error';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { useTranslation } from 'react-i18next';

interface Props {
  category: Category;
  setSelectedCategory: Dispatch<SetStateAction<Category>>;
}

export default function DraggableItems({ category, setSelectedCategory }: Props) {
  const menuRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setModals, setCreateMenuModal, setOneBtnModal, setTwoBtnModal, setCloseTwoBtnModal } = useModalStore();

  const { mutate: deleteCategoryMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });
  const deleteCategory = () => {
    if (!category.id) return;
    deleteCategoryMutate(
      {
        method: 'DELETE',
        url: `/admin/categories/${category.id}`,
      },
      {
        onSuccess: () => {
          setOneBtnModal(t('message.alert'), t('menu.카테고리를삭제하였습니다'), () => {
            queryClient.invalidateQueries({ queryKey: ['categories'] });
          });
        },
        onError(error, variables, context) {
          errorHandler(t, error, setOneBtnModal);
        },
      }
    );
  };

  const { mutate: menuReorderMutate } = useMutation<any, ApiError, ApiProps>({
    mutationFn: api,
  });

  const { currentItems, handleDragStart, handleDragOver, handleDrop, setItems, dragOverIndex } = useDragAndDrop({
    items: [],
    onReorder: (startId, endId) => {
      menuReorderMutate(
        {
          method: 'PATCH',
          url: `/admin/items/order`,
          data: {
            itemIds: [startId, endId],
          },
        },
        {
          onSuccess(data, variables, context) {
            queryClient.invalidateQueries({ queryKey: ['categories'] });
          },
        }
      );
    },
  });

  useEffect(() => {
    if (category.items) {
      setItems(category.items);
    }
  }, [category.items]);

  return (
    <>
      <div
        className={`border border-gray1 rounded-sm py-2 px-1 flex justify-between items-center transition-all duration-200`}
      >
        <span className='flex items-center justify-center'>
          <span className='cursor-move hover:bg-gray1 p-2 rounded-sm'>
            <MenuIcon />
          </span>
          <span
            className='cursor-pointer hover:bg-gray1 p-2 rounded-sm'
            onClick={() => {
              if (menuRef.current) {
                menuRef.current.hidden = !menuRef.current.hidden;
              }
            }}
          >
            <CheckDownIcon width={16} height={16} />
          </span>
          <span className='text-[14px] font-semibold pl-1'>{category.name}</span>
        </span>
        <span>
          <MoreSelect
            list={[
              {
                label: t('menu.수정하기'),
                name: t('menu.수정하기'),
                onClick: () => {
                  setModals('createCategoryModal');
                  setSelectedCategory(category);
                },
              },
              {
                label: t('menu.삭제하기'),
                name: t('menu.삭제하기'),
                onClick: () => {
                  setTwoBtnModal({
                    title: t('message.alert'),
                    contents: t('menu.정말로카테고리를삭제하시겠습니까'),
                    leftText: t('menu.취소'),
                    leftFn: () => {
                      setCloseTwoBtnModal();
                    },
                    rightText: t('menu.삭제'),
                    rightFn: () => {
                      setCloseTwoBtnModal();
                      deleteCategory();
                    },
                  });
                },
              },
            ]}
          />
        </span>
      </div>

      <div ref={menuRef} hidden>
        <ul className='flex flex-col gap-2 pl-[50px] mt-4 text-[12px] '>
          {currentItems.map((menu, index) => (
            <li
              key={menu.id}
              draggable
              onDragStart={(e) => {
                e.stopPropagation();
                handleDragStart(index);
              }}
              onDragOver={(e) => {
                e.stopPropagation();
                handleDragOver(e, index);
              }}
              onDrop={(e) => {
                e.stopPropagation();
                handleDrop(index);
              }}
              className={`transition-all duration-300 active:scale-[1.02] active:shadow-md ${
                dragOverIndex === index ? 'border-2 border-main bg-gray-100 shadow-md' : ''
              }`}
            >
              <DraggableMenu item={menu} categoryName={category.name} />
            </li>
          ))}
        </ul>

        <div className='mt-4 text-end'>
          <button
            onClick={() => {
              setCreateMenuModal(category.id, category.name, category.menuId);
            }}
            className='cursor-pointer text-[12px] md:text-[16px] font-semibold rounded-md border py-1 px-2 text-main hover:text-main5 hover:transition-colors duration-300 hover:strong-pulse'
          >
            {t('menu.새메뉴추가')}
          </button>
        </div>
      </div>
    </>
  );
}
