import React, { useState } from 'react';

type Props = {
  isOn: boolean;
  setOn: () => void;
};

export default function Toggle({ isOn, setOn }: Props) {
  return (
    <div className=' flex justify-center items-center'>
      {/* 배경 */}
      <div
        className={`${
          isOn ? 'bg-white' : 'bg-main1'
        } cursor-pointer relative w-11 h-4 rounded-full flex items-center justify-center ring-2 ring-main1 transition-all duration-100`}
        onClick={setOn}
      >
        {/* 토글 */}
        <span
          className={`${
            isOn ? 'translate-x-[50%] bg-main1' : 'translate-x-[-50%] bg-white'
          } absolute w-[24px] h-[24px] rounded-full transition-transform duration-300 ring-2 ring-main1`}
        />
      </div>
    </div>
  );
}
