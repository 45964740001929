import { useQuery } from '@tanstack/react-query';
import { getRestaurant, GetRestaurant } from 'api/service/restaurant';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import useRestaurantInfoStore from 'store/service/useRestaurantInfo';

type Props = {};

export default function MenuLayout({}: Props) {
  const { setRestaurantInfo } = useRestaurantInfoStore();
  const { restaurantId } = useParams();
  const { data } = useQuery<GetRestaurant>({
    queryKey: ['restaurant', restaurantId, 'info'],
    queryFn: () => getRestaurant(restaurantId),
    enabled: !!restaurantId,
  });
  useEffect(() => {
    if (data?.payload) {
      setRestaurantInfo(data.payload);
    }
  }, [data]);
  return (
    <div className='max-w-[600px] m-auto '>
      <Outlet />
    </div>
  );
}
