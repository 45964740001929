import { ReactComponent as PhonBackground } from 'assets/svg/phonBackground.svg';
import { ReactComponent as BackIcon } from 'assets/svg/back_icon.svg';
import { settingsItemKeys } from 'pages/service/RestaurantMenuInfo';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    name: string;
    phone: string;
    managerPhone: string;
    address: string;
    addressUrl: string;
    originMarking: string;
    snsUrl: string;
    businessHours: string;
  };
};

export default function SettingsPreview({ data }: Props) {
  const { t } = useTranslation();
  const settingsItems = settingsItemKeys.map((item) => ({
    label: t(item.labelKey),
    value: item.value,
  }));

  return (
    <section className='pl-8 pt-4 border-l border-gray1 hidden xl:block'>
      <h2 className='text-gray-600 text-[18px] font-bold'>{t('settings.미리보기')}</h2>
      <div className='mt-6 relative'>
        <div className='absolute z-[-1]'>
          <PhonBackground />
        </div>
        <div className='pt-[63px] w-[375px] h-[667px] *:text-[12px] flex flex-col pl-[48px] pr-[56px]'>
          <div className='font-NEXONLv1GothicOTF relative'>
            <div className='flex items-center justify-between border-b border-b-gray1 pb-4'>
              <span className='size-6'>
                <BackIcon fill='#9C9C9C' width={20} height={20} />
              </span>
              <h1 className='text-[16px] font-bold text-center'>{t('settings.레스토랑정보')}</h1>
              <span className='size-6' />
            </div>
            <ul className='flex flex-col gap-2 mt-8'>
              {settingsItems.map((item, index) => (
                <li key={index} className='flex items-center justify-between gap-3'>
                  <label className='w-1/2 text-[12px] font-semibold whitespace-pre'>{item.label}</label>
                  <span className='w-1/2 text-[12px] text-end break-all'>
                    {data?.[item.value as keyof typeof data] || '-'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
